import { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon
} from "@heroicons/react/24/outline";
import logoImg from "../../images/logo.png";
import { Typography } from "@material-tailwind/react";
import { GiImpactPoint } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import { LiaMapMarkedAltSolid } from 'react-icons/lia';
import { BiMapPin, BiSolidDashboard } from 'react-icons/bi'
import { LuLuggage } from "react-icons/lu";
import { MdOutlineShareLocation, MdAltRoute } from "react-icons/md";
import { GiSteeringWheel } from "react-icons/gi";
import { FaPersonChalkboard, FaPeopleRoof } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import { FaCalendarCheck } from "react-icons/fa";


interface menuActiveProps {
  homeActive?: string;
  schedulesActive?: string;
  loadPointsActive?: string;
  regionsActive?: string;
  luggageActive?: string;
  pickActive?: string;
  routeActive?: string;
  driverActive?: string;
  employeeActive?: string;
  conductorActive?: string;
}

const CustomSideBar = ({
  homeActive,
  schedulesActive,
  loadPointsActive, regionsActive, luggageActive, pickActive, routeActive, driverActive, conductorActive, employeeActive
}: menuActiveProps) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleDeviceType = () => {
      const width = window.innerWidth;
      if (width < 768) {
        //mobile
        setIsCollapsed(true);
      } else if (width >= 768 && width < 1024) {
        //tablet
        setIsCollapsed(true);
      } else {
        //desktop
        setIsCollapsed(false);
      }
    };

    handleDeviceType();

    window.addEventListener("resize", handleDeviceType);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleDeviceType);
    };
  }, []);

  return (
    <Sidebar collapsed={isCollapsed}>
      <Menu className="bg-white h-full">
        {/* logo */}
        <div className="p-3 flex items-center gap-3 w-full">
          <Link className="w-full" to="#">
            <img src={logoImg} alt="logo" />
          </Link>
          <Typography className={`${isCollapsed ? "hidden" : ""}`} variant="h5">
            HSHS
          </Typography>
          <div
            className={`${isCollapsed ? "hidden" : "flex justify-end items-center w-full"
              }`}
          >
            <ChevronDoubleLeftIcon
              className="w-5 h-5 cursor-pointer"
              onClick={() => handleCollapse()}
            />
          </div>
        </div>
        <ChevronDoubleRightIcon
          className={`${isCollapsed
            ? "w-5 h-5 cursor-pointer my-3 flex mr-auto ml-auto"
            : "hidden"
            }`}
          onClick={() => handleCollapse()}
        />

        {/* <div className={`${isCollapsed ? "hidden" : "px-3 w-full my-5"}`}>
            <ServiceSelector />
          </div> */}

        <div className="mt-2">
          <MenuItem
            icon={<BiSolidDashboard className="h-5 w-5" />}
            className={homeActive}
            onClick={() => navigate("/")}
          >
            <p>Home</p>
          </MenuItem>

          <MenuItem
            icon={<FaCalendarCheck className="h-5 w-5" />}
            className={schedulesActive}
            onClick={() => navigate("/allScehdules")}
          >
            <p>Schedules</p>
          </MenuItem>

          <SubMenu label="Location Mgt." icon={<MdOutlineShareLocation className="h-5 w-5" />}>
            <MenuItem
              icon={<BiMapPin className="h-5 w-5" />}
              className={regionsActive}
              onClick={() => navigate("/regions")}
            >
              <p>Regions</p>
            </MenuItem>

            <MenuItem
              icon={<LiaMapMarkedAltSolid className="h-5 w-5" />}
              className={loadPointsActive}
              onClick={() => navigate("/loadingPoints")}
            >
              <p>Loading Points</p>
            </MenuItem>

            <MenuItem
              icon={<GiImpactPoint className="h-5 w-5" />}
              className={pickActive}
              onClick={() => navigate("/pickupPoints")}
            >
              <p>Pickup Points</p>
            </MenuItem>

            <MenuItem
              icon={<MdAltRoute className="h-5 w-5" />}
              className={routeActive}
              onClick={() => navigate("/routes")}
            >
              <p>Routes</p>
            </MenuItem>
          </SubMenu>

          <MenuItem
            icon={<LuLuggage className="h-5 w-5" />}
            className={luggageActive}
            onClick={() => navigate("/luggage-types")}
          >
            <p>Luggage Types</p>
          </MenuItem>

          <SubMenu label="All Staff" icon={<IoIosPeople className="h-5 w-5" />}>
            <MenuItem
              icon={<GiSteeringWheel className="h-5 w-5" />}
              className={driverActive}
              onClick={() => navigate("/drivers")}
            >
              <p>Drivers</p>
            </MenuItem>

            <MenuItem
              icon={<FaPersonChalkboard className="h-5 w-5" />}
              className={conductorActive}
              onClick={() => navigate("/conductors")}
            >
              <p>Conductors</p>
            </MenuItem>

            <MenuItem
              icon={<FaPeopleRoof className="h-5 w-5" />}
              className={employeeActive}
              onClick={() => navigate("/employees")}
            >
              <p>Employees</p>
            </MenuItem>
          </SubMenu>
        </div>
      </Menu>
    </Sidebar>
  );
};

export default CustomSideBar;
