import { Input, Modal, Spin, notification } from 'antd';
import { useState } from 'react';
import { base_hshs_requests } from '../../../helpers/api';
import { RouteConstants } from '../../../helpers/RouteConstants';

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}

const CreateRegionModal = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [theRegionName, setTheRegionName] = useState("")


    //handleCreateRegion
    const handleCreateRegion = async () => {
        if (!theRegionName) {
            notification.warning({ message: "Please enter a region name" })
        }
        else {
            let data = {
                name: theRegionName
            }

            setIsBusy(true)
            try {
                const response = await base_hshs_requests.post(RouteConstants.REGIONS_ROUTE, data)
                console.log(response?.data)
                notification.success({ message: "New region was created successfully" })
                setIsBusy(false)
                handleClose()
                handleReFetch()
            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.message })
            }
        }
    }

    return (
        <>
            <Modal title="Create New Region" open={isOpened} footer={null} keyboard={false} closeIcon={false}>
                <Spin spinning={isBusy}>
                    <hr />
                    <div>
                        <small className='mb-2'>Enter region name</small>
                        <Input className='h-[40px]' value={theRegionName} onChange={(e) => setTheRegionName(e.target.value)} placeholder='E.g: Greater Accra' />

                        <div className="mt-4 flex justify-end gap-3">
                            <button className='px-4 py-2 border-[1px] border-gray-400 rounded-[5px]' onClick={handleClose}>
                                Cancel
                            </button>
                            <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px]' onClick={handleCreateRegion}>
                                Submit
                            </button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default CreateRegionModal
