import { createContext, useContext, useState } from 'react';

const UserContext = createContext<any>(null);

export function UserProvider({ children }: any) {
  const [userImg, setUserImg] = useState(null);

  return (
    <UserContext.Provider value={{ userImg, setUserImg }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
