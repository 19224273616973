import { TrashIcon } from '@heroicons/react/24/outline'
import { Modal, Spin } from 'antd'
import delImg from "../../animations/delImg.gif"

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    isConfirmed: () => void
    isDeleting: boolean
}

const DeleteDialog = ({ isOpened, handleClose, isConfirmed, isDeleting }: modalProps) => {

    return (
        <>
            <Modal title="Confirm Delete" open={isOpened} footer={null} keyboard={false} closeIcon={false}>
                <Spin spinning={isDeleting}>
                    <hr />
                    <div className='flex flex-col justify-center items-center'>
                        <img className='w-[100px]' src={delImg} alt="del" />

                        <div className='mt-2'>
                            <p className='text-md'>Are you sure you want to proceed with this action</p>
                        </div>
                    </div>


                    <div className="mt-5 flex justify-end gap-3">
                        <button className='px-4 py-2 border-[1px] border-gray-400 rounded-[5px]' onClick={handleClose}>
                            Cancel
                        </button>
                        <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] flex gap-2' onClick={isConfirmed}>
                            <TrashIcon className='h-5 w-5' />
                            Delete
                        </button>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default DeleteDialog
