import { useState } from "react";
import { Modal, Spin, notification } from "antd";
import { base_hshs_requests } from "../../../helpers/api";
import { RouteConstants } from "../../../helpers/RouteConstants";
import LoadingPointSelector from "../../../components/shared/loadingPointSelector";

interface modalProps {
  openModal: boolean;
  handleOpenModal: () => void;
  handleClose: () => void
  handleReFetch: () => void;
}

const CreateRouteModal = ({
  openModal,
  handleReFetch,
  handleClose
}: modalProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const [theSource, setTheSource] = useState("")
  const [theDestination, setTheDestination] = useState("")


  //create route
  const handleCreateRoute = async () => {
    if (!theSource || !theDestination) {
      notification.warning({ message: "Please fill out all fields" })
    }
    else {
      try {
        setIsCreating(true);
        let data = {
          "source_id": theSource,
          "destination_id": theDestination
        };

        const response = await base_hshs_requests.post(RouteConstants.ALL_ROUTES, data);
        // console.log(response)
        if (response?.status === 200 || response?.status === 201) {
          notification.success({ message: "New route successfully was created" })
          setIsCreating(false)
          handleClose()
          handleReFetch()
        }
        else {
          notification.error({ message: response?.data?.payload?.message })
          setIsCreating(false)
        }
      } catch (error: any) {
        console.log(error);
        notification.error({ message: error?.response?.data?.message })
        setIsCreating(false)
      }
    }
  };

  return (
    <>
      <Modal title="Create New Route" open={openModal} footer={null} keyboard={false} closeIcon={false}>
        <Spin spinning={isCreating}>
          <hr className="mt-0" />
          <div className="text-black">
            <small className="text-sm">Search source location</small>
            <LoadingPointSelector onChange={(selected) => setTheSource(selected?.value)} />
          </div>

          <div className="text-black mt-4">
            <small className="text-sm">Search destination location</small>
            <LoadingPointSelector onChange={(selected) => setTheDestination(selected?.value)} />
          </div>


          <hr className="my-3" />

          <div className="mt-4 flex justify-end gap-3">
            <button className='px-4 py-2 border-[1px] border-gray-400 rounded-[5px]' onClick={handleClose}>
              Cancel
            </button>
            <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] disabled:cursor-not-allowed' onClick={() => handleCreateRoute()}>
              Submit
            </button>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default CreateRouteModal;
