import {
  CalendarDaysIcon,
  MapPinIcon,
  StopIcon,
} from "@heroicons/react/24/outline";

const GetSchedulesShimmer = () => {
  return (
    <div className="p-[15px] rounded-[10px] shadow-lg bg-white cursor-pointer border-l-[2px] border-l-oya-ghana-red mt-3 lg:mt-0 animate-pulse">
      <div className="flex justify-between items-center">
        <p className="font-medium text-transparent bg-gray-400 w-fit rounded-full h-[10px] mb-2">
          Broadcasting, Mile 11, Ghana
        </p>
        <p className="font-medium text-transparent bg-gray-400 w-fit rounded-full h-[10px] mb-2">
          Fare: GHS 0.01
        </p>
      </div>
      <hr />
      <div className="flex gap-3 items-center mt-3">
        <StopIcon className="h-4 w-4 text-oya-ghana-red mt-2" />
        <p className="text-transparent bg-gray-400 w-fit rounded-full h-[10px]">
          Broadcasting, Mile 11, Ghana
        </p>
      </div>
      <div className="h-[20px] border-l-[1px] border-dashed border-l-oya-ghana-red ml-[7px]"></div>
      <div className="flex gap-3 items-center mb-3">
        <MapPinIcon className="h-4 w-4 text-oya-ghana-red" />
        <p className="text-transparent bg-gray-400 w-fit rounded-full h-[10px]">
          Broadcasting, Mile 11, Ghana
        </p>
      </div>
      <hr />
      <div className="flex justify-between items-center mt-2">
        <div className="flex gap-2 items-center">
          <CalendarDaysIcon className="w-5 h-5" />
          <div>
            <p className="text-transparent bg-gray-400 w-fit rounded-full h-[10px]">
              Start Trip
            </p>
            <p className="text-sm text-transparent bg-gray-400 w-fit rounded-full h-[10px] mt-2">
              2023-07-25T13:11:00.000Z
            </p>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CalendarDaysIcon className="w-5 h-5" />
          <div>
            <p className="text-transparent bg-gray-400 w-fit rounded-full h-[10px]">
              Estimated Arrival
            </p>
            <p className="text-sm text-transparent bg-gray-400 w-fit rounded-full h-[10px] mt-2">
              2023-07-25T13:11:00.000Z
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetSchedulesShimmer;
