import { useState, useEffect, useMemo } from "react";
import {
  ArrowPathIcon, PlusIcon
} from "@heroicons/react/24/outline";
import { Input } from "@material-tailwind/react";
import CustomSideBar from "../../../components/layouts/sidebar";
import CustomNavbar from "../../../components/layouts/navbar";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import { base_hshs_requests } from "../../../helpers/api";
import { RouteConstants } from "../../../helpers/RouteConstants";
import CreateEmployeeModal from "./createEmployeeModal";


const AllEmployees = () => {
  const [reFetch, setReFetch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [employeesList, setEmployeesList] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0
  });

  //open create modal
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const handleOpenCreate = () => {
    setOpenCreateModal(true)
  }
  const handleCloseCreate = () => {
    setOpenCreateModal(false)
  }

  //onload
  useEffect(() => {
    const fetchAllEmployees = async () => {
      setIsFetching(true);
      try {
        let offset = pagination.pageIndex * pagination.pageSize;

        // const defaultFilters = [];

        // if (selectedStatus) {
        //   defaultFilters.push({
        //     f: 'creator.name',
        //     o: '=',
        //     p: [selectedStatus],
        //   });
        // }

        const response = await base_hshs_requests.get(`${RouteConstants.ALL_EMPLOYEES}?limit=${pagination.pageSize
          }&offset=${offset}`);
        console.log(response?.data?.payload?.items);
        setEmployeesList(response?.data?.payload?.items);
        setTotal(response?.data?.total);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchAllEmployees()
  }, [pagination.pageIndex, pagination.pageSize, reFetch])


  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: any) => row?.contact?.phone,
        id: "phone",
        header: "Phone",
      },
      {
        accessorFn: (row: any) => row?.creator?.name,
        id: "creator",
        header: "Created By",
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY hh:mma"),
        id: "date",
        header: "Date Created",
      },
      {
        accessorFn: (row: any) => row?.updater?.name,
        id: "upd",
        header: "Updated By",
      },
      {
        accessorFn: (row: any) =>
          moment(row?.updated_at).format("Do MMM YYYY hh:mma"),
        id: "updated",
        header: "Date Updated",
      }
    ],

    // eslint-disable-next-line
    []
  );

  //handleReFetch
  const handleReFetch = () => {
    setReFetch(!reFetch)
  }



  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar conductorActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full overflow-auto pb-20">
              <div className="flex justify-between items-center">
                <p className="text-lg font-bold">All Employees</p>

                <button
                  className="px-3 lg:py-2 py-3 bg-oya-ghana-red text-white rounded-[30px] flex gap-2 items-center"
                  onClick={() => handleOpenCreate()}
                >
                  <PlusIcon className="w-5 h-5" />
                  <p className="hidden lg:block">Create New</p>
                </button>
              </div>

              <div className="mt-5">
                <div className="p-4 rounded-[5px] bg-white shadow-lg w-full flex justify-between items-center">
                  <div className="flex gap-3">
                    <button className="h-10 w-10 rounded-[5px] bg-oya-ghana-red text-white flex justify-center items-center" onClick={() => handleReFetch()}>
                      <ArrowPathIcon className="h-5 w-5" />
                    </button>

                    <div className="lg:w-[300px]">
                      <Input
                        label="Search employee name"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        disabled
                      />
                    </div>
                  </div>

                  <div></div>
                </div>


                <div className="mt-4 mb-20">
                  <MaterialReactTable
                    enableColumnResizing
                    columns={columns}
                    data={employeesList}
                    enableRowSelection={false}
                    rowCount={total}
                    enableColumnActions={false}
                    enableDensityToggle={false}
                    enableFilters={false}
                    enableFullScreenToggle={false}
                    enableSorting={false}
                    manualPagination
                    onPaginationChange={setPagination}
                    state={{
                      pagination,
                      showProgressBars: isFetching,
                      density: "compact",
                      isLoading: isFetching,
                    }}
                    defaultColumn={{
                      maxSize: 400,
                      minSize: 80,
                      size: 120,
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* create driver modal */}
      {openCreateModal ? (
        <CreateEmployeeModal
          isOpened={openCreateModal}
          handleClose={handleCloseCreate}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}

    </>
  );
};

export default AllEmployees;
