import { useState } from 'react'
import Select from 'react-select';
import { base_hshs_requests } from '../../helpers/api';
import { RouteConstants } from '../../helpers/RouteConstants';
import debounce from 'lodash/debounce';
import { PickUpPayload } from '../../pages/hsh/pickupPoints/model.payload';

interface theProps {
    onChange: (data: any) => void
    defaultValue?: any
}

const PickupPointSelector = ({ onChange, defaultValue }: theProps) => {

    const [isFetching, setIsFetching] = useState(false)
    const [pickupPoints, setPickupPoints] = useState([]);



    const fetchLoadingPoints = debounce((inputValue) => {
        if (inputValue) {
            setIsFetching(true);

            const defaultFilter = [{
                f: 'name',
                o: 'contains',
                p: [inputValue],
            }];

            base_hshs_requests.get(`${RouteConstants.PICK_UP_POINTS}?filters=${JSON.stringify(defaultFilter)}`)
                .then((response) => {

                    console.log(response?.data?.payload?.items);
                    const rawData = response?.data?.payload?.items
                    const mapped = rawData.map((item: PickUpPayload) => (
                        { label: item?.name, value: item?.id }
                    ))
                    setPickupPoints(mapped);
                    setIsFetching(false);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, 300);

    return (
        <>
            <Select
                className="w-full"
                isClearable={true}
                isSearchable={true}
                options={pickupPoints}
                isLoading={isFetching}
                placeholder="E.g: Kaneshie Market"
                onChange={onChange}
                defaultValue={defaultValue}
                onInputChange={(val) => fetchLoadingPoints(val)}
            />
        </>
    )
}

export default PickupPointSelector
