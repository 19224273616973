import { Input, Modal, Spin, notification } from 'antd';
import { useEffect, useState } from 'react';
import { base_hshs_requests } from '../../../helpers/api';
import { RouteConstants } from '../../../helpers/RouteConstants';
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
    getLatLng,
} from "react-google-places-autocomplete";
import { GOOGLE_KEY } from '../../../helpers/constants';
import RegionSelector from '../../../components/shared/regionSelector';
import { PickUpPayload } from './model.payload';

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    dataForEdit: PickUpPayload
}

const EditPickupPoint = ({ isOpened, handleClose, handleReFetch, dataForEdit }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [placeName, setPlaceName] = useState("");
    const [long, setLong] = useState<any>(0);
    const [lat, setLat] = useState<any>(0);
    const [theRegion, setTheRegion] = useState("")
    const [theLandMark, setTheLandMark] = useState("")

    //onload
    useEffect(() => {
        setPlaceName(dataForEdit?.name)
        setTheLandMark(dataForEdit?.landmark)
        setTheRegion(dataForEdit?.region?.name)
        setLong(dataForEdit?.coordinates?.longitude)
        setLat(dataForEdit?.coordinates?.latitude)
    }, [dataForEdit])

    //handlePlaceSelected
    const handlePlaceSelected = (selected: any) => {
        if (selected) {
            console.log(selected.value);
            geocodeByPlaceId(selected.value.place_id)
                .then((results) => {
                    console.log(results);
                    getLatLng(results[0]).then(({ lat, lng }) => {
                        console.log(lat, lng);
                        setLat(lat);
                        setLong(lng);
                    });
                })
                .catch((error) => console.error(error));
            setPlaceName(selected.value.description);
        } else {
            console.log("No option selected");
            setPlaceName("");
        }
    };

    //handleUpdate
    const handleUpdate = async () => {
        if (!placeName || !theRegion || !theLandMark) {
            notification.warning({ message: "Please fill out all fields" })
        }
        else {
            let data = {
                "name": placeName,
                "latitude": lat,
                "longitude": long,
                "landmark": theLandMark,
                "region_id": theRegion
            }

            setIsBusy(true)
            try {
                const response = await base_hshs_requests.put(`${RouteConstants.PICK_UP_POINTS}/${dataForEdit?.id}`, data)
                console.log(response?.data)
                notification.success({ message: "Pickup point has been updated" })
                setIsBusy(false)
                handleClose()
                handleReFetch()
            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.message })
            }
        }
    }

    return (
        <>
            <Modal title="Update Pickup Point" open={isOpened} footer={null} keyboard={false} closeIcon={false}>
                <Spin spinning={isBusy}>
                    <hr />
                    <div>
                        <div>
                            <small className='mb-2'>Search place name</small>
                            <GooglePlacesAutocomplete
                                apiKey={`${GOOGLE_KEY}`}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ["gh"],
                                    },
                                }}
                                selectProps={{
                                    placeholder: "E.g: Dansoman Last Stop",
                                    isClearable: true,
                                    isSearchable: true,
                                    onChange: (selected) => handlePlaceSelected(selected),
                                    defaultValue: { label: dataForEdit?.name, value: dataForEdit?.name }
                                }}
                            />
                        </div>

                        <div className="mt-4">
                            <small className='mb-2'>Search region name</small>
                            <RegionSelector defaultValue={{ label: dataForEdit?.region?.name, value: dataForEdit?.region?.name }} onChange={(data: any) => setTheRegion(data?.value)} />
                        </div>

                        <div className="mt-4">
                            <small className='mb-2'>Enter a landmark</small>
                            <Input className='w-full h-[40px]' value={theLandMark} onChange={(e) => setTheLandMark(e.target.value)} placeholder='Football Field' />
                        </div>

                        <div className="mt-4 flex justify-end gap-3">
                            <button className='px-4 py-2 border-[1px] border-gray-400 rounded-[5px]' onClick={handleClose}>
                                Cancel
                            </button>
                            <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px]' onClick={handleUpdate}>
                                Update
                            </button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default EditPickupPoint
