import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner,
} from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { postApi, putApi } from "../../../utils/api";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import CustomToasts from "../../../components/general/toasts";

interface modalProps {
  isModOpen: boolean;
  handleIsModOpen: () => void;
  srcLocation: string;
  desLocation: string;
  tripType: string;
  hiringData: any;
}

const CreateHiringReqModal = ({
  isModOpen,
  handleIsModOpen,
  srcLocation,
  desLocation,
  tripType,
  hiringData,
}: modalProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isCreating, setIsCreating] = useState(false);
  const [isError, setIsError] = useState(false);
  const [minPassenger, setMinPassenger] = useState<any>("");
  const [maxPassenger, setMaxPassenger] = useState<any>("");
  const [scheduleData, setScheduleData] = useState<any>([])

  //onload
  useEffect(() => {
    const fromLS: any = localStorage.getItem("scheduleDetails")
    const hiringData = JSON.parse(fromLS)
    console.log(hiringData);
    setScheduleData(hiringData)
  }, []);

  // create hirirng req
  const handleCreateHiringReq = () => {
    if (minPassenger === "" || maxPassenger === "") {
      setIsError(true);
    } else {
      setIsCreating(true);
      let data = JSON.stringify({
        passenger_count: {
          maximum: parseInt(maxPassenger),
          minimum: parseInt(minPassenger),
        },
        source_location: {
          city: scheduleData?.source?.city_name,
          placeName: scheduleData?.source?.place_name,
          region: scheduleData?.source?.region,
          latitude: parseInt(scheduleData?.source?.latitiude),
          longitude: parseInt(scheduleData?.source?.longitude),
        },
        destination_location: {
          city: scheduleData?.destination?.city_name,
          placeName: scheduleData?.destination?.place_name,
          region: scheduleData?.destination?.region,
          latitude: parseInt(scheduleData?.destination?.latitiude),
          longitude: parseInt(scheduleData?.destination?.longitude),
        },
        description: scheduleData?.description,
        start_date: scheduleData?.departure,
        end_date: scheduleData?.estimated_arrival,
        hiring_type: "Individual Hiring",
        features: scheduleData?.features ? scheduleData?.features.map((feature: any) => feature?.name) : [],
        return_type: scheduleData?.returnable === false ? "One Way Trip" : "Round Trip",
        schedule_type: "HSHS",
        other_visits: scheduleData?.other_visits || [],
      });

      console.log(JSON.parse(data));
      postApi(`${HIRING_URL}${globalVariables.create_hring_request}`, data)
        .then((response: any) => {
          // console.log(response);
          if (response?.data?.statusCode === 200) {
            const hiringId = response?.data?.payload?.id;

            //run approval
            let approveData = JSON.stringify({
              "hiring_id": parseInt(hiringId),
              "decision": "Approved"
            })
            putApi(`${HIRING_URL}${globalVariables.approver_hiring_req}`, approveData).then((response: any) => {
              console.log(response)
              if (response?.data?.statusCode === 200) {
                setIsCreating(false);
                handleIsModOpen();
                CustomToasts.success_toast("Hiring request was created successfully");
                navigate(`/tripDetails/${id}?hid=${hiringId}`);
              }
            })

          }
        })
        .catch((error) => {
          console.log(error);
          setIsCreating(false);
        });
    }
  };

  //handleMinPassChnage
  const handleMinPassChange = (e: any) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    if (isNaN(parsedValue) || parsedValue < 0) {
      setMinPassenger('');
    } else {
      setMinPassenger(parsedValue);
    }
  };

  //handleMaxPassChnage
  const handleMaxPassChange = (e: any) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    if (isNaN(parsedValue) || parsedValue < 0) {
      setMaxPassenger('');
    } else {
      setMaxPassenger(parsedValue);
    }
  };

  return (
    <>
      <Dialog open={isModOpen} handler={handleIsModOpen}>
        <DialogHeader>Create Hiring Request</DialogHeader>
        <DialogBody divider>
          <div className="text-black">
            <label>Source Location</label>
            <Input value={srcLocation} onChange={() => { }} disabled />
          </div>
          <div className="text-black mt-3">
            <label>Destination</label>
            <Input value={desLocation} onChange={() => { }} disabled />
          </div>
          <div className="mt-3 flex gap-5">
            <div className="text-black">
              <label>Min Passengers</label>
              <Input
                placeholder="Eg:10"
                min={0}
                value={minPassenger}
                onChange={handleMinPassChange}
                type="number"
                error={isError}
              />
            </div>
            <div className="text-black">
              <label>Max Passengers</label>
              <Input
                placeholder="Eg:10"
                min={0}
                value={maxPassenger}
                onChange={handleMaxPassChange}
                type="number"
                error={isError}
              />
            </div>
          </div>
          <div className="mt-3 flex gap-3 text-black">
            <p className="font-semibold">Trip Type:</p>
            <p>{tripType}</p>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleIsModOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <button
            className="px-3 py-2 bg-oya-ghana-red text-white rounded-[5px] disabled:bg-gray-500 disabled:cursor-not-allowed"
            onClick={() => handleCreateHiringReq()}
            disabled={isCreating}
          >
            {isCreating ? <Spinner /> : <span>Continue</span>}
          </button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default CreateHiringReqModal;
