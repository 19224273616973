import "./css/App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HSHHomePage from "./pages/hsh/Home";
import AllScehdules from "./pages/hsh/schedules/allSchedules";
import ScehduleDetails from "./pages/hsh/schedules/scheduleDetails";
import ViewLoadedBuses from "./pages/hsh/schedules/viewLoadedBuses";
import ViewBookings from "./pages/hsh/schedules/viewBookings";
import UnderConstruction from "./pages/construction";
import ProtectedRoutes from "./helpers/router";
import ViewAssignedBuses from "./pages/hsh/schedules/viewAssignedBuses";
import TripDetails from "./pages/hsh/schedules/tripDetails";
import TripManifest from "./pages/hsh/schedules/tripManifest";
import UnautorizedAccess from "./pages/unautorized";
import { UserProvider } from "./context/userContext";
import AllRegions from "./pages/hsh/regions/allRegions";
import AllLuggageTypes from "./pages/hsh/luggageTypes/luggageTypes";
import AllPickupPoints from "./pages/hsh/pickupPoints/allPickupPoints";
import AllLoadingPoints from "./pages/hsh/loadingPoints/allLoadingPoints";
import AllRoutes from "./pages/hsh/allRoutes/allRoutes";
import RoutePickupPoint from "./pages/hsh/allRoutes/routePickupPoint/routePickupPoint";
import AllDrivers from "./pages/hsh/drivers/allDrivers";
import AllConductors from "./pages/hsh/conductors/allConductors";
import AllEmployees from "./pages/hsh/employees/allEmployees";

function App() {
  return (
    <>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="*" element={<h4>Page not found!</h4>} />
            <Route path="/unauthorized" element={<UnautorizedAccess />} />

            {/* under construction */}
            <Route
              path="/working"
              element={
                <ProtectedRoutes>
                  <UnderConstruction />
                </ProtectedRoutes>
              }
            />

            {/* hsh operation */}
            <Route
              path="/"
              element={
                <ProtectedRoutes>
                  <HSHHomePage />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/loadingPoints"
              element={
                <ProtectedRoutes>
                  <AllLoadingPoints />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/regions"
              element={
                <ProtectedRoutes>
                  <AllRegions />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/allScehdules"
              element={
                <ProtectedRoutes>
                  <AllScehdules />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/scheduleDetails/:id"
              element={
                <ProtectedRoutes>
                  <ScehduleDetails />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/scheduleBuses/:id"
              element={
                <ProtectedRoutes>
                  <ViewLoadedBuses />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/assignedBuses/:id"
              element={
                <ProtectedRoutes>
                  <ViewAssignedBuses />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/tripDetails/:id"
              element={
                <ProtectedRoutes>
                  <TripDetails />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/tripManifest/:id"
              element={
                <ProtectedRoutes>
                  <TripManifest />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/scheduleBookings/:id"
              element={
                <ProtectedRoutes>
                  <ViewBookings />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/luggage-types"
              element={
                <ProtectedRoutes>
                  <AllLuggageTypes />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/pickupPoints"
              element={
                <ProtectedRoutes>
                  <AllPickupPoints />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/routes"
              element={
                <ProtectedRoutes>
                  <AllRoutes />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/routes/:id"
              element={
                <ProtectedRoutes>
                  <RoutePickupPoint />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/drivers"
              element={
                <ProtectedRoutes>
                  <AllDrivers />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/conductors"
              element={
                <ProtectedRoutes>
                  <AllConductors />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/employees"
              element={
                <ProtectedRoutes>
                  <AllEmployees />
                </ProtectedRoutes>
              }
            />
          </Routes>
        </Router>
      </UserProvider>
    </>
  );
}

export default App;
