import { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { GOOGLE_KEY } from "../../../helpers/constants";
import { Modal, Spin, notification, Input } from "antd";
import RegionSelector from "../../../components/shared/regionSelector";
import { base_hshs_requests } from "../../../helpers/api";
import { RouteConstants } from "../../../helpers/RouteConstants";
import { LoadingPointsPayload } from "./payload.model";

interface modalProps {
  openEditModal: boolean;
  handleOpenEditModal: () => void;
  handleReFetch: () => void;
  selectedLoadingPoint: LoadingPointsPayload;
  handleClose: () => void
}

const EditLoadingPointModal = ({
  openEditModal,
  handleReFetch,
  handleClose,
  selectedLoadingPoint }: modalProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [placeName, setPlaceName] = useState("");
  const [aliasLoadingPointName, setAliasLoadingPointName] = useState("");
  const [theRegion, setTheRegion] = useState("");
  const [long, setLong] = useState<any>(0);
  const [lat, setLat] = useState<any>(0);
  const [theLandMark, setTheLandMark] = useState("")

  //onload
  useEffect(() => {
    setTheLandMark(selectedLoadingPoint?.landmark)
    setTheRegion(selectedLoadingPoint?.region?.id)
    setPlaceName(selectedLoadingPoint?.name)
  }, [selectedLoadingPoint])

  const handleCheckboxChecked = (event: any) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const handlePlaceSelected = (selected: any) => {
    if (selected) {
      console.log(selected.value);
      geocodeByPlaceId(selected.value.place_id)
        .then((results) => {
          console.log(results);
          setTheRegion(results[0]?.address_components[2]["long_name"]);
          getLatLng(results[0]).then(({ lat, lng }) => {
            console.log(lat, lng);
            setLat(lat);
            setLong(lng);
          });
        })
        .catch((error) => console.error(error));
      setPlaceName(selected.value.description);
    } else {
      console.log("No option selected");
      setPlaceName("");
    }
  };

  //create loading point
  const createLoadingPoint = async () => {
    if (!placeName || !theRegion || !theLandMark) {
      notification.warning({ message: "Please fill out all fields" })
    }
    else if (isCheckboxChecked && !aliasLoadingPointName) {
      notification.warning({ message: "Please enter an alternative name" })
    }
    else {
      try {
        setIsCreating(true);
        let data = {
          name: aliasLoadingPointName ? aliasLoadingPointName : placeName,
          region_id: theRegion,
          latitude: lat,
          longitude: long,
          landmark: theLandMark
        };

        const response = await base_hshs_requests.put(`${RouteConstants.LOADING_POINTS}/${selectedLoadingPoint?.id}`, data);
        // console.log(response)
        if (response?.status === 200 || response?.status === 201) {
          notification.success({ message: "Pickup point updated successfully" })
          setIsCreating(false)
          handleClose()
          handleReFetch()
        }
        else {
          notification.error({ message: response?.data?.payload?.message })
          setIsCreating(false)
        }
      } catch (error: any) {
        console.log(error);
        notification.error({ message: error?.response?.data?.message })
      }
    }
  };

  return (
    <>
      <Modal title="Edit Loading Point" open={openEditModal} footer={null} keyboard={false} closeIcon={false}>
        <Spin spinning={isCreating}>
          <hr className="mt-0" />
          <div className="text-black">
            <small className="text-sm">Search Location</small>
            <GooglePlacesAutocomplete
              apiKey={`${GOOGLE_KEY}`}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["gh"],
                },
              }}
              selectProps={{
                placeholder: "Search...",
                isClearable: true,
                isSearchable: true,
                onChange: (selected) => handlePlaceSelected(selected),
                noOptionsMessage: () => "E.g: Kasoa tollbooth",
                defaultValue: { label: selectedLoadingPoint?.name, value: selectedLoadingPoint?.name }
              }}
            />
          </div>

          <div className="text-black mt-4">
            <small className="text-sm">Loading Point Name</small>
            <div className="flex justify-between">
              <p className="text-gray-500 lg:w-[400px]">
                Would you like to provide an alternative name for the location above?
              </p>
              <input
                className="h-[20px] w-[20px]"
                type="checkbox"
                checked={isCheckboxChecked}
                onChange={handleCheckboxChecked}
              />
            </div>

            {isCheckboxChecked ? (
              <div className="mt-4">
                <Input
                  placeholder="E.g: Kaneshie Market"
                  value={aliasLoadingPointName}
                  onChange={(e) => setAliasLoadingPointName(e.target.value)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="mt-4">
            <small className="text-sm">Select region</small>
            <RegionSelector defaultValue={{ label: selectedLoadingPoint?.region?.name, value: selectedLoadingPoint?.region?.name }} onChange={(selected) => setTheRegion(selected?.value)} />
          </div>

          <div className="mt-4">
            <small className='mb-2 text-sm'>Enter a landmark</small>
            <Input className='w-full h-[40px]' value={theLandMark} onChange={(e) => setTheLandMark(e.target.value)} placeholder='Football Field' />
          </div>

          <hr className="my-3" />

          <div className="mt-4 flex justify-end gap-3">
            <button className='px-4 py-2 border-[1px] border-gray-400 rounded-[5px]' onClick={handleClose}>
              Cancel
            </button>
            <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px] disabled:cursor-not-allowed' onClick={() => createLoadingPoint()}>
              Submit
            </button>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default EditLoadingPointModal;
