import {
  ArrowLeftIcon,
  ArrowPathIcon,
  BanknotesIcon,
  ClockIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomNavbar from "../../../components/layouts/navbar";
import CustomSideBar from "../../../components/layouts/sidebar";
import noDataImg from "../../../images/noDataImg.svg";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import { getApi } from "../../../utils/api";
import { Option, Select } from "@material-tailwind/react";
import moment from "moment";
import MigrateUserModal from "./migrateUserModal";

const ViewBookings = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [bookingsPayload, setBookingsPayload] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [payloadSkip, setPayloadSkip] = useState(0);
  const [filters, setFilters] = useState({
    limit: 12,
    skip: 0,
    order: { updated_at: "DESC" },
    filter: {
      schedule: { id: id },
    },
  });
  const [sourceId, setSourceId] = useState("");
  const [destId, setDestId] = useState("");
  const [schId, setSchId] = useState("");
  const [booksId, setBooksId] = useState<any>([]);
  const [migrateModalOpen, setMigrateModalOpen] = useState(false);
  const [dataForModal, setDataForModal] = useState<any>(null);
  const handleMigrateModalOpen = () => {
    setMigrateModalOpen(!migrateModalOpen);
  };

  useEffect(() => {
    const fetchBookings = async () => {
      setIsFetching(true);
      try {
        const response = await getApi(
          `${HIRING_URL}${globalVariables.get_bookings_route
          }?filterobject=${JSON.stringify(filters)}`
        );
        console.log(response?.data);
        setBookingsPayload(response?.data?.payload);
        setTotal(response?.data?.total);
        setPayloadSkip(response?.data?.skip);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBookings();
  }, [id, filters, reFetch]);

  // handle refetch
  const handleRefetch = () => {
    setReFetch(!reFetch);
    setBooksId([]);
  };

  // pagination logic start
  const handleNext = () => {
    if (page >= 1) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        limit: 12,
        skip: prevFilters.limit * page,
      }));
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        limit: 12,
        skip: prevFilters.skip - prevFilters.limit,
      }));
      setPage((prevPage) => prevPage - 1);
    }
  };
  // pagination logic end

  // handle tick function
  const tickBooking = (selected: any, checked: any) => {
    setSchId(selected?.schedule?.id);
    setSourceId(selected?.schedule?.source?.id);
    setDestId(selected?.schedule?.destination?.id);
    if (checked) {
      setBooksId([...booksId, selected?.id]);
    } else {
      setBooksId(booksId.filter((bookId: any) => bookId !== selected?.id));
    }
  };
  // handle migrate function
  const handleMigrate = () => {
    const data = {
      scheduleId: schId,
      bookingIds: booksId,
      sourceId: sourceId,
      destinationId: destId,
    };
    setDataForModal(data);
    handleMigrateModalOpen();
  };

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar schedulesActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full overflow-y-auto pb-20">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <button
                    className="px-3 py-2 bg-oya-ghana-red text-white rounded-[30px] flex gap-2 items-center"
                    onClick={() => navigate(`/scheduleDetails/${id}`)}
                  >
                    <ArrowLeftIcon className="w-5 h-5" />
                    Back
                  </button>
                  <p className="text-lg font-semibold">Bookings</p>
                </div>
                <button
                  className="px-3 lg:py-2 py-3 bg-oya-ghana-red text-white rounded-full lg:rounded-[30px] flex gap-2 items-center"
                  onClick={() => handleRefetch()}
                >
                  <ArrowPathIcon className="w-5 h-5" />
                  <p className="hidden lg:block">Refresh</p>
                </button>
              </div>

              {/* content */}
              <div className="lg:flex lg:justify-between lg:items-center">
                <div className="mb-3 mt-4 lg:w-72">
                  <Select label="Filter">
                    <Option>All Bookings</Option>
                    <Option>Non Migrated Paid Bookings</Option>
                    <Option>Paid Bookings</Option>
                  </Select>
                </div>
                {booksId.length === 0 ? (
                  <></>
                ) : (
                  <button
                    className="px-3 py-2 bg-oya-ghana-red text-white rounded-[30px]"
                    onClick={() => handleMigrate()}
                  >
                    Migrate User(s)
                  </button>
                )}
              </div>
              {isFetching ? (
                // show shimmer
                <div className="mt-3 lg:grid lg:grid-cols-3 gap-4">
                  {["", "", "", "", "", "", "", "", ""].map((item, i) => (
                    <div
                      key={i}
                      className="mt-2 lg:mt-0 bg-white shadow-lg p-[15px] w-full border-l-[2px] border-l-oya-ghana-red rounded-[10px] animate-pulse"
                    >
                      <div className="flex justify-between">
                        <p className="text-transparent bg-gray-300 h-3 rounded-full">
                          Click to migrate
                        </p>
                        <div className="h-[15px] w-[15px] text-transparent bg-gray-300"></div>
                      </div>
                      <div className="flex gap-2 items-center mt-2">
                        <div className="h-5 w-5 text-transparent bg-gray-300"></div>
                        <p className="text-transparent bg-gray-300 h-3 rounded-full">
                          Name: Loading...
                        </p>
                      </div>
                      <div className="flex gap-2 items-center mt-1">
                        <div className="h-5 w-5 text-transparent bg-gray-300"></div>
                        <p className="text-transparent bg-gray-300 h-3 rounded-full">
                          Contact: Loading...
                        </p>
                      </div>
                      <hr className="my-3" />
                      <div className="flex gap-2 items-center mt-2">
                        <div className="h-5 w-5 text-transparent bg-gray-300"></div>
                        <p className="text-transparent bg-gray-300 h-3 rounded-full">
                          Name: Loading...
                        </p>
                      </div>
                      <div className="flex gap-2 items-center mt-1">
                        <div className="h-5 w-5 text-transparent bg-gray-300"></div>
                        <p className="text-transparent bg-gray-300 h-3 rounded-full">
                          Contact: Loading...
                        </p>
                      </div>
                      <hr className="my-3" />
                      <div className="flex gap-2 items-center mt-1">
                        <div className="h-5 w-5 text-transparent bg-gray-300"></div>
                        <p className="text-transparent bg-gray-300 h-3 rounded-full">
                          Booking Time: Loading...
                        </p>
                      </div>
                      <div className="flex gap-2 items-center mt-1">
                        <div className="h-5 w-5 text-transparent bg-gray-300"></div>
                        <p className="text-transparent bg-gray-300 h-3 rounded-full">
                          Payment Status: Loading...
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {bookingsPayload.length === 0 ? (
                    <div className="flex flex-col justify-center items-center h-full">
                      <img className="w-[100px]" src={noDataImg} alt="noBus" />
                      <p className="text-gray-500 forn-medium mt-2">
                        No bookings relating to this schedule...
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="mt-3 lg:grid lg:grid-cols-3 gap-4 mb-20">
                        {bookingsPayload.map((item: any, i) => (
                          <div
                            key={item?.id}
                            className="mt-2 lg:mt-0 bg-white shadow-lg p-[15px] w-full border-l-[2px] border-l-oya-ghana-red rounded-[10px]"
                          >
                            {item?.payment?.status === "Approved" &&
                              item?.hiring_request === null ? (
                              <div className="flex justify-between">
                                <p>Click to migrate</p>
                                <input
                                  type="checkbox"
                                  className="h-[15px] w-[15px] cursor-pointer"
                                  checked={booksId.includes(item?.id)}
                                  onChange={(e) =>
                                    tickBooking(item, e.target.checked)
                                  }
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <div>
                              <p className="font-medium">Booking Account Holder</p>
                              <div className="flex gap-2 items-center">
                                <UserIcon className="h5 w-5" />
                                <p>Name: {item?.user?.name}</p>
                              </div>
                              <div className="flex gap-2 items-center mt-1">
                                <PhoneIcon className="h5 w-5" />
                                <p>Contact: {item?.user?.phone}</p>
                              </div>
                            </div>
                            <hr className="my-3" />
                            <div className="mt-2">
                              <p className="font-medium">Ward Details</p>
                              <div className="flex gap-2 items-center">
                                <UserIcon className="h5 w-5" />
                                <p>Name: {item?.attendee_name}</p>
                              </div>
                              <div className="flex gap-2 items-center mt-1">
                                <PhoneIcon className="h5 w-5" />
                                <p>Contact: {item?.attendee_phone}</p>
                              </div>
                            </div>
                            <hr className="my-3" />
                            <div className="flex gap-2 items-center mt-1">
                              <ClockIcon className="h5 w-5" />
                              <p>
                                Booking Time:{" "}
                                {moment(item?.created_at).format(
                                  "Do MMM YYYY hh:mm A"
                                )}
                              </p>
                            </div>
                            <div className="flex gap-2 items-center mt-1">
                              <BanknotesIcon className="h5 w-5" />
                              <p>Payment Status: {item?.payment?.status}</p>
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Pagination component */}
                      <div className="flex justify-end items-center gap-3 mb-20">
                        <button
                          onClick={() => handlePrevious()}
                          className="bg-oya-ghana-red hover:bg-red-800 text-white font-bold py-2 px-4 rounded disabled:cursor-not-allowed"
                          disabled={page === 1}
                        >
                          Previous
                        </button>
                        <div>Page: {page}</div>
                        <button
                          onClick={() => handleNext()}
                          className="bg-oya-ghana-red hover:bg-red-800 text-white font-bold py-2 px-4 rounded disabled:cursor-not-allowed"
                          disabled={payloadSkip === total}
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* open migration modal */}
      {migrateModalOpen ? (
        <MigrateUserModal
          openMigrateModal={migrateModalOpen}
          handleOpenMigrateModal={handleMigrateModalOpen}
          necessaryDetails={dataForModal}
          handleReFetch={handleRefetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ViewBookings;
