import { useState, useEffect } from "react";
import { Collapse } from "@material-tailwind/react";
import UserProfile from "../general/profile";

const CustomNavbar = () => {
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <div className="w-full px-4 py-2 shadow-md">
      <div className="flex items-center justify-between text-blue-gray-900">
        {/* left */}
        <div></div>

        {/* middle */}
        <div className="hidden lg:block"></div>

        {/* right */}

        <UserProfile />
      </div>

      {/* on mobile */}
      <Collapse open={openNav}>...</Collapse>
    </div>
  );
};

export default CustomNavbar;
