import { Input, Modal, Spin, notification } from 'antd';
import { useEffect, useState } from 'react';
import { base_hshs_requests } from '../../../helpers/api';
import { RouteConstants } from '../../../helpers/RouteConstants';
import { LuggagePayload } from './model.luggage';

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    dataForEdit: LuggagePayload
}

const EditLuggageType = ({ isOpened, handleClose, handleReFetch, dataForEdit }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [theName, setTheName] = useState("")

    //onload
    useEffect(() => {
        setTheName(dataForEdit?.code)
    }, [dataForEdit])

    //handleUpdateLuggage
    const handleUpdateLuggage = async () => {
        if (!theName) {
            notification.warning({ message: "Please fill out the field" })
        }
        else {
            let data = {
                "name": theName,
                "code": theName.toUpperCase()
            }

            setIsBusy(true)
            try {
                const response = await base_hshs_requests.put(`${RouteConstants.LUGGAGE_TYPES}/${dataForEdit?.id}`, data)
                console.log(response?.data)
                notification.success({ message: `${theName} was updated successfully` })
                setIsBusy(false)
                handleClose()
                handleReFetch()
            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.message })
            }
        }
    }

    return (
        <>
            <Modal title="Update Luggage Type" open={isOpened} footer={null} keyboard={false} closeIcon={false}>
                <Spin spinning={isBusy}>
                    <hr />
                    <div>
                        <div>
                            <small className='mb-2'>Luggage type name</small>
                            <Input className='h-[40px]' value={theName} onChange={(e) => setTheName(e.target.value)} placeholder='E.g: Briefcase' />
                        </div>

                        <div className="mt-4 flex justify-end gap-3">
                            <button className='px-4 py-2 border-[1px] border-gray-400 rounded-[5px]' onClick={handleClose}>
                                Cancel
                            </button>
                            <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px]' onClick={handleUpdateLuggage}>
                                Update
                            </button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default EditLuggageType
