import {
  ArrowLeftIcon,
  BanknotesIcon,
  CalendarDaysIcon,
  MapPinIcon,
  ShoppingBagIcon,
  StopIcon,
} from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomSideBar from "../../../components/layouts/sidebar";
import CustomNavbar from "../../../components/layouts/navbar";
import { getApi } from "../../../utils/api";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import moment from "moment";
import GetSchedulesShimmer from "./getSchedulesShimmer";
import { Spinner } from "@material-tailwind/react";
import noLuggage from "../../../images/noLuggage.svg";
import axios from "axios";
import CustomAlerts from "../../../components/general/alerts";
import CustomToasts from "../../../components/general/toasts";

const ScehduleDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [scheduleDetails, setScheduleDetails] = useState<any>([]);
  const [isMarking, setIsMarking] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  // fetch schedule details
  useEffect(() => {
    const fetchSchedules = async () => {
      setIsFetching(true);
      try {
        const response = await getApi(
          `${HIRING_URL}${globalVariables.get_single_schedule}?id=${id}`
        );
        console.log(response?.data);
        setScheduleDetails(response?.data?.payload);
        localStorage.setItem("scheduleDetails", JSON.stringify(response?.data?.payload))
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSchedules();
  }, [id, reFetch]);

  // handle mark as completed
  const handleMarkAsComplete = () => {
    CustomAlerts.question_alert(
      "Confirm",
      "Do you want to mark this schedule as completed?",
      "Proceed"
    ).then((action) => {
      if (action.isConfirmed) {
        setIsMarking(true);
        axios
          .put(`${HIRING_URL}/v2/schedules/${id}/mark-as-completed`)
          .then((response) => {
            CustomToasts.success_toast("Mark as complete successful!");
            console.log(response);
            setReFetch(!reFetch);
          })
          .catch((error) => {
            console.error("Error performing PUT request:", error);
          });
      }
    });
  };

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar schedulesActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full overflow-y-auto pb-20">
              <div className="flex items-center gap-3">
                <button
                  className="px-3 py-2 bg-oya-ghana-red text-white rounded-[30px] flex gap-2 items-center"
                  onClick={() => navigate("/allScehdules")}
                >
                  <ArrowLeftIcon className="w-5 h-5" />
                  Back
                </button>
                <p className="text-lg font-semibold">Schedule Details</p>
              </div>

              {/* content */}
              {isFetching ? (
                <div className="mt-3">
                  <GetSchedulesShimmer />
                </div>
              ) : (
                <div className="p-[15px] rounded-[10px] shadow-lg bg-white border-l-[2px] border-l-oya-ghana-red mt-3">
                  <div className="flex justify-between items-center">
                    <p><span className="font-medium">Created On: </span>
                      {moment(scheduleDetails?.created_at).format(
                        "Do MMM YYYY - hh:mm a"
                      )}
                    </p>
                    <p className="flex justify-end font-medium">
                      Fare:{" "}
                      {`${scheduleDetails?.country?.currency_code} ${scheduleDetails?.amount}`}
                    </p>
                  </div>
                  <hr />
                  <div className="flex gap-3 items-center mt-3">
                    <StopIcon className="h-4 w-4 text-oya-ghana-red" />
                    <p className="truncate lg:w-full w-[230px]">
                      {scheduleDetails?.source?.place_name}
                    </p>
                  </div>
                  <div className="h-[20px] border-l-[1px] border-dashed border-l-oya-ghana-red ml-[7px]"></div>
                  <div className="flex gap-3 items-center mb-3">
                    <MapPinIcon className="h-4 w-4 text-oya-ghana-red" />
                    <p className="truncate lg:w-full w-[230px]">
                      {scheduleDetails?.destination?.place_name}
                    </p>
                  </div>

                  <div>
                    <p>Created By: <span>{scheduleDetails?.facilitator?.name}</span></p>
                  </div>

                  <hr />
                  <div className="flex justify-between items-center mt-2">
                    <div className="flex gap-2 items-center">
                      <CalendarDaysIcon className="w-5 h-5" />
                      <div>
                        <p>Start Trip</p>
                        <p className="text-gray-500 text-sm">
                          {moment(scheduleDetails?.departure).format(
                            "Do MMM YYYY - hh:mm a"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <CalendarDaysIcon className="w-5 h-5" />
                      <div>
                        <p>Estimated Arrival</p>
                        <p className="text-gray-500 text-sm">
                          {moment(scheduleDetails?.estimated_arrival).format(
                            "Do MMM YYYY - hh:mm a"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <hr className="mt-5" />

              <p className="mt-3">Luggages</p>
              {isFetching ? (
                <div className="flex justify-center items-center">
                  <Spinner color="red" />
                </div>
              ) : (
                <>
                  {scheduleDetails?.luggages?.length === 0 ? (
                    <div className="flex justify-center items-center flex-col mt-2">
                      <img className="w-[120px]" src={noLuggage} alt="noLug" />
                      <p className="text-sm text-gray-500">No luggages added</p>
                    </div>
                  ) : (
                    <div className="lg:grid lg:grid-cols-4 gap-4 mt-2">
                      {scheduleDetails?.luggages?.map(
                        (item: any, i: number) => (
                          <div
                            key={i}
                            className="p-[15px] bg-white shadow-md mt-3 lg:mt-0"
                          >
                            <div className="flex gap-3 items-center">
                              <ShoppingBagIcon className="w-5 h-5 text-oya-ghana-red" />
                              <p className="mt-1">{item?.name}</p>
                            </div>
                            <div className="flex gap-3 items-center">
                              <BanknotesIcon className="w-5 h-5 text-oya-ghana-red" />
                              <p className="mt-1">GH¢ {item?.amount}</p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}

                  <hr className="mt-5" />

                  {/* buttons */}
                  <div className="mt-5 lg:grid lg:grid-cols-2 gap-5 mb-20">
                    <button
                      className="flex justify-center items-center bg-white border-[1px] border-oya-ghana-red rounded-[5px] shadow-lg w-full h-[45px] mt-5 lg:mt-0"
                      onClick={() => navigate(`/scheduleBuses/${id}`)}
                    >
                      View Loaded Buses
                    </button>
                    <button
                      className="flex justify-center items-center bg-white border-[1px] border-oya-ghana-red rounded-[5px] shadow-lg w-full h-[45px] mt-5 lg:mt-0"
                      onClick={() =>
                        navigate(
                          `/assignedBuses/${id}?src=${scheduleDetails?.source?.place_name
                          }&des=${scheduleDetails?.destination?.place_name
                          }&type=${scheduleDetails?.returnable
                            ? "Return Trip"
                            : "One Way Trip"
                          }`
                        )
                      }
                    >
                      View Assigned Buses
                    </button>
                    <button
                      className="flex justify-center items-center text-white bg-oya-ghana-red rounded-[5px] shadow-lg w-full h-[45px] mt-5 lg:mt-0"
                      onClick={() => navigate(`/scheduleBookings/${id}`)}
                    >
                      View Bookings
                    </button>
                    {scheduleDetails?.completed === true ? (
                      <></>
                    ) : (
                      <button
                        className="flex justify-center items-center bg-green-500 text-white rounded-[5px] shadow-lg w-full h-[45px] mt-5 lg:mt-0 disabled:cursor-not-allowed"
                        onClick={() => handleMarkAsComplete()}
                        disabled={isMarking}
                      >
                        {isMarking ? (
                          <Spinner />
                        ) : (
                          <span>Mark As Completed</span>
                        )}
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScehduleDetails;
