import { Input, Modal, Spin, notification } from 'antd';
import { useState } from 'react';
import { base_hshs_requests } from '../../../helpers/api';
import { RouteConstants } from '../../../helpers/RouteConstants';

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}

const CreateLuggageType = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [theName, setTheName] = useState("")


    //handleCreateLuggage
    const handleCreateLuggage = async () => {
        if (!theName) {
            notification.warning({ message: "Please fill out the field" })
        }
        else {
            let data = {
                "name": theName,
                "code": theName.toUpperCase()
            }

            setIsBusy(true)
            try {
                const response = await base_hshs_requests.post(RouteConstants.LUGGAGE_TYPES, data)
                console.log(response?.data)
                notification.success({ message: `${theName} was created successfully` })
                setIsBusy(false)
                handleClose()
                handleReFetch()
            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.message })
            }
        }
    }

    return (
        <>
            <Modal title="Create Luggage Type" open={isOpened} footer={null} keyboard={false} closeIcon={false}>
                <Spin spinning={isBusy}>
                    <hr />
                    <div>
                        <div>
                            <small className='mb-2'>Luggage type name</small>
                            <Input className='h-[40px]' value={theName} onChange={(e) => setTheName(e.target.value)} placeholder='E.g: Briefcase' />
                        </div>

                        <div className="mt-4 flex justify-end gap-3">
                            <button className='px-4 py-2 border-[1px] border-gray-400 rounded-[5px]' onClick={handleClose}>
                                Cancel
                            </button>
                            <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px]' onClick={handleCreateLuggage}>
                                Submit
                            </button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default CreateLuggageType
