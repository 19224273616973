import { Input, Modal, Spin, notification } from 'antd';
import { useEffect, useState } from 'react';
import { base_hshs_requests } from '../../../helpers/api';
import { RouteConstants } from '../../../helpers/RouteConstants';
import { RegionPayload } from './model.regions';

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    dataForEdit: RegionPayload
}

const EditRegionModal = ({ isOpened, handleClose, handleReFetch, dataForEdit }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [theRegionName, setTheRegionName] = useState("")

    //onload
    useEffect(() => {
        setTheRegionName(dataForEdit?.name)
    }, [dataForEdit])

    //handleUpdateRegion
    const handleUpdateRegion = async () => {
        if (!theRegionName) {
            notification.warning({ message: "Please enter a region name" })
        }
        else {
            let data = {
                name: theRegionName
            }

            setIsBusy(true)
            try {
                const response = await base_hshs_requests.put(`${RouteConstants.REGIONS_ROUTE}/${dataForEdit?.id}`, data)
                console.log(response?.data)
                notification.success({ message: "Region was updated successfully" })
                setIsBusy(false)
                handleClose()
                handleReFetch()
            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.message })
            }
        }
    }

    return (
        <>
            <Modal title="Update Region" open={isOpened} footer={null} keyboard={false} closeIcon={false}>
                <Spin spinning={isBusy}>
                    <hr />
                    <div>
                        <small className='mb-2'>Enter region name</small>
                        <Input className='h-[40px]' value={theRegionName} onChange={(e) => setTheRegionName(e.target.value)} placeholder='E.g: Greater Accra' />

                        <div className="mt-4 flex justify-end gap-3">
                            <button className='px-4 py-2 border-[1px] border-gray-400 rounded-[5px]' onClick={handleClose}>
                                Cancel
                            </button>
                            <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px]' onClick={handleUpdateRegion}>
                                Update
                            </button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default EditRegionModal
