export const globalVariables = {
  create_loading_point_route: "/loading_point",
  get_loading_point_route: "/loading_point/all",
  get_schedules_route: "/schedules/all",
  get_single_schedule: "/schedules",
  create_schedule_route: "/schedules",
  get_bookings_route: "/bookings/all",
  migrate_users_route: "/bookings/offload",
  get_hiring_requests: "/hiring_request/all",
  get_one_hiring_request: "/hiring_request",
  get_hiring_events: "/hiring_event/all",
  create_hring_request: "/hiring_request",
  update_trip_status: "/hiring_request/status-change",
  assign_bus_route: "/hiring_request/assign-bus",
  assign_driver_route: "/hiring_request/assign-driver",
  get_all_passengers: "/passengers/all",
  find_driver_by_phone: "/v2/find-by-phone",
  register_driver: "/v2/auth/register",
  approver_hiring_req: "/hiring_request/approve",
};
