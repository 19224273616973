const version = "/v1";

export const RouteConstants = {
  CREATE_LOADING_POINT: "/loading_point",
  ALL_LOADING_POINTS: "/loading_point/all",
  ALL_SCHEDULES: "/schedules/all",
  SINGLE_SCHEDULE: "/schedules",
  CREATE_SCHEDULE: "/schedules",
  ALL_BOOKINGS: "/bookings/all",
  MIGRATE_USERS: "/bookings/offload",
  ALL_HIRING_REQUESTS: "/hiring_request/all",
  SINGLE_HIRING_REQUEST: "/hiring_request",
  ALL_HIRING_EVENTS: "/hiring_event/all",
  CREATE_HIRING_REQUEST: "/hiring_request",
  UPDATE_TRIP_STATUS: "/hiring_request/status-change",
  ASSIGN_BUS: "/hiring_request/assign-bus",
  ASSIGN_DRIVER: "/hiring_request/assign-driver",
  ALL_PASSENGERS: "/passengers/all",
  FIND_BY_PHONE: "/v2/find-by-phone",
  REGISTER_DRIVER: "/v2/auth/register",
  APPROVE_HIRING_REQUEST: "/hiring_request/approve",
  REGIONS_ROUTE: `${version}/regions`,
  LUGGAGE_TYPES: `${version}/luggage-types`,
  PICK_UP_POINTS: `${version}/pickup-points`,
  LOADING_POINTS: `${version}/loading-points`,
  ALL_ROUTES: `${version}/routes`,
  ALL_DRIVERS: `${version}/drivers`,
  ALL_CONDUCTORS: `${version}/conductors`,
  ALL_EMPLOYEES: `${version}/employees`,
  ALL_ROLES: `/v2/me/hshs-manager-roles`,
};
