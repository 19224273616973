import { useState, useEffect, useMemo } from "react";
import {
  ArrowPathIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Input } from "@material-tailwind/react";
import CustomSideBar from "../../../components/layouts/sidebar";
import CustomNavbar from "../../../components/layouts/navbar";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import { base_hshs_requests } from "../../../helpers/api";
import { RouteConstants } from "../../../helpers/RouteConstants";
import { Dropdown, Space, type MenuProps, notification } from 'antd';
import DeleteDialog from "../../../components/shared/deleteDialog";
import CreateConductorModal from "./createConductorModal";
import EditConductorModal from "./editConductorModal";
import { ConductorPayload } from "./payload.model";


const AllConductors = () => {
  const [reFetch, setReFetch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [conductorsList, setConductorsList] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0
  });
  const [savedData, setSavedData] = useState<any>(null)

  //open create modal
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const handleOpenCreate = () => {
    setOpenCreateModal(true)
  }
  const handleCloseCreate = () => {
    setOpenCreateModal(false)
  }

  //open edit modal
  const [openEditModal, setOpenEditModal] = useState(false)
  const handleOpenEdit = () => {
    setOpenEditModal(true)
  }
  const handleCloseEdit = () => {
    setOpenEditModal(false)
  }

  //delete dialog
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const handleOpenDelete = () => {
    setOpenDeleteModal(true)
  }
  const handleCloseDelete = () => {
    setOpenDeleteModal(false)
  }

  //onload
  useEffect(() => {
    const fetchAllConductors = async () => {
      setIsFetching(true);
      try {
        let offset = pagination.pageIndex * pagination.pageSize;

        // const defaultFilters = [];

        // if (selectedStatus) {
        //   defaultFilters.push({
        //     f: 'creator.name',
        //     o: '=',
        //     p: [selectedStatus],
        //   });
        // }

        const response = await base_hshs_requests.get(`${RouteConstants.ALL_CONDUCTORS}?limit=${pagination.pageSize
          }&offset=${offset}`);
        // console.log(response?.data?.payload?.items);
        setConductorsList(response?.data?.payload?.items);
        setTotal(response?.data?.total);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchAllConductors()
  }, [pagination.pageIndex, pagination.pageSize, reFetch])

  //dropdown menu items
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <button className="w-full flex gap-2" onClick={() => handleOpenEdit()}>
          <PencilSquareIcon className="h-5 w-5" />
          Edit Conductor
        </button>
      ),
    },
    {
      key: '2',
      label: (
        <button className="w-full flex gap-2" onClick={() => handleOpenDelete()}>
          <TrashIcon className="h-5 w-5 text-red-500" />
          Delete Conductor
        </button>
      ),
    },
  ]


  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: ConductorPayload) => row?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: ConductorPayload) => row?.contact?.phone,
        id: "phone",
        header: "Phone",
      },
      {
        accessorFn: (row: ConductorPayload) => row?.creator?.name,
        id: "creator",
        header: "Created By",
      },
      {
        accessorFn: (row: ConductorPayload) =>
          moment(row?.created_at).format("Do MMM YYYY hh:mma"),
        id: "date",
        header: "Date Created",
      },
      {
        accessorFn: (row: ConductorPayload) => row?.updater?.name,
        id: "upd",
        header: "Updated By",
      },
      {
        accessorFn: (row: ConductorPayload) =>
          moment(row?.updated_at).format("Do MMM YYYY hh:mma"),
        id: "updated",
        header: "Date Updated",
      },
      {
        id: 'more',
        header: 'Action',
        accessorFn: (row: ConductorPayload) =>
          <Dropdown
            trigger={['click']}
            menu={{
              items,
            }}
            placement='bottomRight'
          >
            <button
              onClick={() => {
                setSavedData(row)
              }}
            >
              <Space>
                <EllipsisVerticalIcon className="h-5 w-5" />
              </Space>
            </button>
          </Dropdown>,
        size: 5
      },
    ],

    // eslint-disable-next-line
    []
  );

  //handleReFetch
  const handleReFetch = () => {
    setReFetch(!reFetch)
  }

  //deleteConductor
  const deleteConductor = async () => {
    setIsDeleting(true)
    try {
      const response = await base_hshs_requests.delete(`${RouteConstants.ALL_CONDUCTORS}/${savedData?.id}`)
      console.log(response?.data)
      notification.success({ message: "Conductor was deleted successfully" })
      setIsDeleting(false)
      handleCloseDelete()
      handleReFetch()
    } catch (error: any) {
      console.log(error)
      setIsDeleting(false)
      notification.error({ message: error?.response?.data?.message })
    }
  }

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar conductorActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full overflow-auto pb-20">
              <div className="flex justify-between items-center">
                <p className="text-lg font-bold">All Conductors</p>

                <button
                  className="px-3 lg:py-2 py-3 bg-oya-ghana-red text-white rounded-[30px] flex gap-2 items-center"
                  onClick={() => handleOpenCreate()}
                >
                  <PlusIcon className="w-5 h-5" />
                  <p className="hidden lg:block">Create New</p>
                </button>
              </div>

              <div className="mt-5">
                <div className="p-4 rounded-[5px] bg-white shadow-lg w-full flex justify-between items-center">
                  <div className="flex gap-3">
                    <button className="h-10 w-10 rounded-[5px] bg-oya-ghana-red text-white flex justify-center items-center" onClick={() => handleReFetch()}>
                      <ArrowPathIcon className="h-5 w-5" />
                    </button>

                    <div className="lg:w-[300px]">
                      <Input
                        label="Search conductor name"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        disabled
                      />
                    </div>
                  </div>

                  <div></div>
                </div>


                <div className="mt-4 mb-20">
                  <MaterialReactTable
                    enableColumnResizing
                    columns={columns}
                    data={conductorsList}
                    enableRowSelection={false}
                    rowCount={total}
                    enableColumnActions={false}
                    enableDensityToggle={false}
                    enableFilters={false}
                    enableFullScreenToggle={false}
                    enableSorting={false}
                    manualPagination
                    onPaginationChange={setPagination}
                    state={{
                      pagination,
                      showProgressBars: isFetching,
                      density: "compact",
                      isLoading: isFetching,
                    }}
                    defaultColumn={{
                      maxSize: 400,
                      minSize: 80,
                      size: 120,
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* create driver modal */}
      {openCreateModal ? (
        <CreateConductorModal
          isOpened={openCreateModal}
          handleClose={handleCloseCreate}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}

      {/* open edit modal */}
      {openEditModal ? (
        <EditConductorModal
          isOpened={openEditModal}
          handleClose={handleCloseEdit}
          handleReFetch={handleReFetch}
          conductorData={savedData}
        />
      ) : (
        <></>
      )}

      {/* open delete dialog */}
      {openDeleteModal ? (
        <DeleteDialog
          isOpened={openDeleteModal}
          handleClose={handleCloseDelete}
          isConfirmed={deleteConductor}
          isDeleting={isDeleting}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AllConductors;
