import {
  ArrowLeftIcon,
  ArrowPathIcon,
  CalendarDaysIcon,
  MapPinIcon,
  StopIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomNavbar from "../../../components/layouts/navbar";
import CustomSideBar from "../../../components/layouts/sidebar";
import UpdateTripEvents from "./tripEventsModal";
import AssignBusModal from "./assignBusModal";
import AssignDriverModal from "./assignDriverModal";
import { getApi } from "../../../utils/api";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import { Spinner } from "@material-tailwind/react";
import moment from "moment";

const TripDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openTripStatsModal, setOpenTripStatsModal] = useState(false);
  const handleOpenTripStatsModal = () => {
    setOpenTripStatsModal(!openTripStatsModal);
  };
  const [openAssignBusModal, setOpenAssignBusModal] = useState(false);
  const handleOpenAssignBusModal = () => {
    setOpenAssignBusModal(!openAssignBusModal);
  };
  const [openAssignDriverModal, setOpenAssignDriverModal] = useState(false);
  const handleOpenAssignDriverModal = () => {
    setOpenAssignDriverModal(!openAssignDriverModal);
  };

  const [reFetch, setReFetch] = useState(false);
  const [srcPlace, setSrcPlace] = useState("");
  const [desPlace, setDesPlace] = useState("");
  const [tripType, setTripType] = useState<any>("");
  const [isFetching, setIsFetching] = useState(false);
  const [singleHiringReq, setSingleHiringReq] = useState<any>([]);
  const [theHiringId, setTheHiringId] = useState<any>("");

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const hiringId = urlParams.get("hid");
    const tripTypee = urlParams.get("type");
    setTheHiringId(hiringId);
    setTripType(tripTypee);
    //des src type

    const fetchHiringReq = async () => {
      try {
        setIsFetching(true);
        const response = await getApi(
          `${HIRING_URL}${globalVariables.get_one_hiring_request}?id=${hiringId}`
        );
        console.log(response);
        if (response?.status === 200) {
          setSingleHiringReq(response?.data?.payload);
          setSrcPlace(response?.data?.payload?.source?.place_name);
          setDesPlace(response?.data?.payload?.destination?.place_name);
          setTripType(response?.data?.payload?.return_type);
          setIsFetching(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchHiringReq();
  }, [id, reFetch]);

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar schedulesActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full overflow-y-auto pb-20">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <button
                    className="px-3 py-2 bg-oya-ghana-red text-white rounded-[30px] flex gap-2 items-center"
                    onClick={() =>
                      navigate(
                        `/assignedBuses/${id}?src=${srcPlace}&des=${desPlace}&type=${tripType}`
                      )
                    }
                  >
                    <ArrowLeftIcon className="w-5 h-5" />
                    Back
                  </button>
                  <p className="text-lg font-semibold">Trip Details</p>
                </div>
                <div className="flex gap-4">
                  <button
                    className="h-[40px] w-[40px] rounded-full bg-oya-ghana-red text-white flex justify-center items-center"
                    onClick={() =>
                      navigate(`/tripManifest/${id}?hid=${theHiringId}`)
                    }
                  >
                    <UsersIcon className="w-5 h-5" />
                  </button>
                  <button
                    className="h-[40px] w-[40px] rounded-full bg-oya-ghana-red text-white flex justify-center items-center"
                    onClick={() => setReFetch(!reFetch)}
                  >
                    <ArrowPathIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>

              {/* content */}
              <div className="h-full mb-40 lg:mb-20">
                {isFetching ? (
                  <div className="flex justify-center items-center h-full">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="p-[15px] rounded-[10px] shadow-lg bg-white border-l-[2px] border-l-oya-ghana-red mt-3 cursor-pointer">
                      <div className="flex justify-between items-center">
                        <p className="font-semibold">
                          {singleHiringReq?.creator?.name}
                        </p>
                        <p className="font-semibold text-sm">
                          {singleHiringReq?.approval_status}
                        </p>
                      </div>
                      <p className="text-sm">
                        {
                          singleHiringReq?.passenger_summary_data
                            ?.min_passengers
                        }{" "}
                        to{" "}
                        {
                          singleHiringReq?.passenger_summary_data
                            ?.max_passengers
                        }{" "}
                        expected,{" "}
                        {
                          singleHiringReq?.passenger_summary_data
                            ?.actual_passengers
                        }{" "}
                        onboarded
                      </p>
                      <hr />
                      <div className="flex gap-3 items-center mt-3">
                        <StopIcon className="h-4 w-4 text-oya-ghana-red" />
                        <p className="truncate lg:w-full w-[230px]">
                          {singleHiringReq?.source?.place_name}
                        </p>
                      </div>
                      <div className="h-[20px] border-l-[1px] border-dashed border-l-oya-ghana-red ml-[7px]"></div>
                      <div className="flex gap-3 items-center mb-3">
                        <MapPinIcon className="h-4 w-4 text-oya-ghana-red" />
                        <p className="truncate lg:w-full w-[230px]">
                          {singleHiringReq?.destination?.place_name}
                        </p>
                      </div>
                      <hr />
                      <div className="flex justify-between items-center mt-2">
                        <div className="flex gap-2 items-center">
                          <CalendarDaysIcon className="w-5 h-5" />
                          <div>
                            <p>Start Trip</p>
                            <p className="text-gray-500 text-sm">
                              {moment(singleHiringReq?.start_date).format(
                                "Do MMM YYYY - hh:mm a"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <CalendarDaysIcon className="w-5 h-5" />
                          <div>
                            <p>End Trip</p>
                            <p className="text-gray-500 text-sm">
                              {moment(singleHiringReq?.end_date).format(
                                "Do MMM YYYY - hh:mm a"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {singleHiringReq?.approval_status === "Pending" ? (
                      <div className="flex justify-end mt-2">
                        <button className="px-3 py-2 rounded-[5px] bg-gray-500 text-white">
                          Decline
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}

                    <hr className="my-3 border-gray-300" />

                    <div className="lg:grid lg:grid-cols-2 mt-2">
                      <div className="mt-3">
                        <p className="font-semibold text-lg">
                          Request Bus Features
                        </p>
                        <ul>
                          {singleHiringReq?.features?.map(
                            (item: any, i: number) => (
                              <li key={i}>
                                {i + 1}. {item?.name}
                              </li>
                            )
                          )}
                        </ul>
                      </div>

                      {/* <div className="mt-3">
                        <p className="font-semibold text-lg">
                          Creator Information
                        </p>
                        <p>
                          <span className="font-medium">Name:</span>{" "}
                          {singleHiringReq?.creator?.name}
                        </p>
                      </div> */}
                    </div>

                    <hr className="my-3 border-gray-300" />

                    <div className="lg:grid lg:grid-cols-2 mt-2">
                      <div className="mt-3">
                        <p className="font-semibold text-lg">Bus Details</p>
                        <div className="flex gap-5 items-center">
                          {singleHiringReq?.bus === null ? (
                            <>
                              <p>Click to assign a bus to this request</p>
                              <button
                                className="px-3 py-2 bg-oya-ghana-red text-white rounded-[5px]"
                                onClick={() => handleOpenAssignBusModal()}
                              >
                                Assign
                              </button>
                            </>
                          ) : (
                            <>
                              <div>
                                <p>
                                  <span className="font-medium">
                                    Bus Model:{" "}
                                  </span>{" "}
                                  {singleHiringReq?.bus?.model}
                                </p>
                                <p>
                                  <span className="font-medium">
                                    Bus Number:{" "}
                                  </span>{" "}
                                  {singleHiringReq?.bus?.registeration}
                                </p>
                                <div>
                                  <p>Assigned By: <span>{singleHiringReq?.creator?.name}</span></p>
                                </div>
                              </div>
                              <button
                                className="px-3 py-2 bg-oya-ghana-red text-white rounded-[5px]"
                                onClick={() => handleOpenAssignBusModal()}
                              >
                                Change
                              </button>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="mt-3">
                        <p className="font-semibold text-lg">Driver Details</p>
                        <div className="flex gap-5 items-center">
                          {singleHiringReq?.driver === null ? (
                            <>
                              <p>Click to assign a driver to this request</p>
                              <button
                                className="px-3 py-2 bg-oya-ghana-red text-white rounded-[5px] disabled:cursor-not-allowed disabled:bg-gray-500"
                                onClick={() => handleOpenAssignDriverModal()}
                                disabled={singleHiringReq?.bus === null}
                              >
                                Assign
                              </button>
                            </>
                          ) : (
                            <>
                              <div>
                                <p>
                                  <span className="font-medium">Name: </span>{" "}
                                  {singleHiringReq?.driver?.name}
                                </p>
                                <p>
                                  <span className="font-medium">Phone: </span>{" "}
                                  {singleHiringReq?.driver?.phone}
                                </p>
                              </div>
                              <button
                                className="px-3 py-2 bg-oya-ghana-red text-white rounded-[5px]"
                                onClick={() => handleOpenAssignDriverModal()}
                              >
                                Change
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <hr className="my-3 border-gray-300" />

                    <div className="lg:grid lg:grid-cols-2 mt-2 pb-20">
                      <div className="mt-4">
                        <p className="font-semibold text-lg">Trip Events</p>
                        <button
                          className="px-3 py-2 bg-oya-ghana-red text-white rounded-[5px] mt-2"
                          onClick={() => handleOpenTripStatsModal()}
                        >
                          + Update Event
                        </button>
                      </div>
                      <div>
                        {singleHiringReq?.hiring_events?.map(
                          (item: any, i: number) => (
                            <>
                              <div
                                key={i}
                                className="mt-4 flex gap-3 items-center"
                              >
                                <div className="flex gap-2 items-center">
                                  <div className="h-3 w-3 bg-oya-ghana-red rounded-full"></div>
                                  <p className="font-medium">{item?.name}</p>
                                </div>

                                <p className="text-gray-500">
                                  {moment(item?.updated_at).format(
                                    "Do MMM YYYY - hh:mm A"
                                  )}
                                </p>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* open trip status modal */}
      {openTripStatsModal ? (
        <UpdateTripEvents
          openTripEvents={openTripStatsModal}
          handleOpenTripEvents={handleOpenTripStatsModal}
          hiringId={theHiringId}
          handleRefetch={() => setReFetch(!reFetch)}
        />
      ) : (
        <></>
      )}

      {/* open assign bus modal */}
      {openAssignBusModal ? (
        <AssignBusModal
          openAssignBus={openAssignBusModal}
          handleOpenAssignBus={handleOpenAssignBusModal}
          hiringId={theHiringId}
          handleRefetch={() => setReFetch(!reFetch)}
          oldData={singleHiringReq?.bus}
        />
      ) : (
        <></>
      )}

      {/* open assign driver modal */}
      {openAssignDriverModal ? (
        <AssignDriverModal
          openAssignBus={openAssignDriverModal}
          handleOpenAssignBus={handleOpenAssignDriverModal}
          hiringId={theHiringId}
          handleRefetch={() => setReFetch(!reFetch)}
          oldData={singleHiringReq?.driver}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TripDetails;
