import { redirect } from "../utils/functions";
import { ACCOUNTS_URL, COOKIES_DOMAIN } from "./constants";

export const getCookie = (cookieName: any) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(";");
  for (let index = 0; index < cookiesArray.length; index++) {
    let cookie = cookiesArray[index];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};

const deleteCookie = (name: string) => {
  window.document.cookie = `${name}=;domain=${COOKIES_DOMAIN};path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const logout = () => {
  // document.cookie.split(";").forEach(function (c) {
  //   document.cookie = c
  //     .replace(/^ +/, "")
  //     .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  // });

  // window.localStorage.clear();
  // window.location.href = `${ACCOUNTS_URL}?appref=${window.location.href}`;
  window.localStorage.clear();
  redirect(`${ACCOUNTS_URL}/logout?appref=${window.location.origin}/`);
};

export const switchRole = () => {
  deleteCookie("oya_selected_user_role");
  window.location.reload();
};

export const setCookie = (cname: string, cvalue: string, exdays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = `oya_${cname}=${cvalue};${expires};path=/;domain=${COOKIES_DOMAIN}`;
};

export const setCookieJson = (cname: string, cvalue: string, exdays = 7) => {
  setCookie(cname, JSON.stringify(cvalue), exdays);
};
