import {
  Typography,
} from "@material-tailwind/react";
import { PowerIcon } from "@heroicons/react/24/outline";
import { getCookie, logout } from "../../helpers/utils";
import { useEffect, useState } from "react";
import { useUserContext } from "../../context/userContext";
import { AUTHMS_URL } from "../../helpers/constants";
import axios from "axios";
import { Avatar, Dropdown } from 'antd';


const UserProfile = () => {
  const user = getCookie("oya_user_info");
  const parsedUser = JSON.parse(user);
  const token = getCookie("oya_token");
  const [visible, setVisible] = useState(false);


  const { userImg, setUserImg } = useUserContext();

  useEffect(() => {
    axios.get(`${AUTHMS_URL}/v2/accounts/${parsedUser?.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      // console.log(response?.data)
      setUserImg(response?.data?.payload?.image)
    })
      .catch((error) => {
        console.log(error)
      })
  }, [parsedUser, token, setUserImg])


  //dropdown items
  const items: any = [
    {
      type: "group",
      label: "Menu",
    },
    // {
    //   icon: <LinkIcon className="h-5 w-5" />,
    //   onClick: () => {
    //     switchRole();
    //   },
    //   label: (
    //     <button className="pl-4 text-oya-ghana-header-h7 font-medium text-black">
    //       Switch Role
    //     </button>
    //   ),
    //   key: "1",
    // },
    {
      icon: <PowerIcon className="h-5 w-5 text-[#7C0000]" />,
      onClick: () => {
        logout();
      },
      label: (
        <button className="pl-4 text-oya-ghana-header-h7 font-medium text-black">
          Logout
        </button>
      ),
      key: "1",
    },
  ];


  return (
    <>
      {/* <Menu placement="bottom-end">
        <MenuHandler>
          <div className="flex items-center gap-3 cursor-pointer">
            <Avatar
              variant="circular"
              alt="user"
              size="sm"
              src={
                parsedUser?.image ||
                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              }
            />
            <div>
              <Typography variant="small" className="font-semibold">
                {`${parsedUser?.first_name} ${parsedUser?.last_name}`}
              </Typography>
              <Typography variant="small" className="font-normal mt-0">
                {parsedUser?.phone}
              </Typography>
            </div>
          </div>
        </MenuHandler>
        <MenuList>
          <Link to="#" onClick={() => switchRole()}>
            <MenuItem className="flex items-center gap-2">
              <LinkIcon strokeWidth={2} className="h-4 w-4" />
              <Typography variant="small" className="font-normal">
                Switch Role
              </Typography>
            </MenuItem>
          </Link>
          <hr className="my-2 border-blue-gray-50" />
          <Link to="#" onClick={() => logout()}>
            <MenuItem className="flex items-center gap-2 ">
              <PowerIcon strokeWidth={2} className="h-4 w-4" />
              <Typography variant="small" className="font-normal">
                Sign Out
              </Typography>
            </MenuItem>
          </Link>
        </MenuList>
      </Menu> */}
      <div className="flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl">

        <Dropdown
          trigger={["click"]}
          menu={{ items }}
          overlayStyle={{
            boxShadow: "#1d2a9f 0px 1px 4px",
            width: 256,
            borderRadius: 8,
          }}
          onOpenChange={() => setVisible(!visible)}
          placement="bottomRight"
          arrow
        >
          <button
            type="button"
            className="flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl"
          >
            <div className="flex items-center user">
              {userImg ? (
                <Avatar
                  src={userImg}
                  className="appear rounded-full object-cover w-12 h-12 md:w-14 md:h-14"
                  alt="User"
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-12 h-12 md:w-14 md:h-14 text-oya-ghana-green"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}

              <div className="text-left pl-2 pr-4 leading-snug hidden md:block">
                <Typography className="text-oya-ghana-body-normal font-medium">
                  {parsedUser?.name || (
                    <div className="appear animate-pulse h-5 w-40 bg-gray-200 rounded" />
                  )}
                </Typography>
                <div className="text-oya-ghana-body-sb font-normal">
                  {parsedUser?.phone || (
                    <div className="appear animate-pulse h-5 mt-2 w-24 bg-gray-200 rounded" />
                  )}
                </div>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="10"
                viewBox="0 0 18 10"
                stroke="currentColor"
                className="w-4 h-4 text-oya-ghana-green md:mb-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 6.18373L16.0003 0.661255L18 2.23878L9 9.33879L0 2.23878L1.99969 0.661255L9 6.18373Z"
                />
              </svg>
            </div>
          </button>
        </Dropdown>

      </div>
    </>
  );
};

export default UserProfile;
