import CustomNavbar from "../components/layouts/navbar";
import CustomSideBar from "../components/layouts/sidebar";
import constructionImg from "../images/constructionImg.svg";

const UnderConstruction = () => {
  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar homeActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            {/* page content */}
            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full">
              <div className="flex flex-col justify-center items-center h-full">
                <img
                  className="lg:w-[300px]"
                  src={constructionImg}
                  alt="work"
                />
                <p className="text-xl">This Page Is Under Construction</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnderConstruction;
