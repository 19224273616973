import {
  ArrowLeftIcon,
  ClockIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomNavbar from "../../../components/layouts/navbar";
import CustomSideBar from "../../../components/layouts/sidebar";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import { getApi } from "../../../utils/api";
import { Spinner } from "@material-tailwind/react";
import noDataImg from "../../../images/noDataImg.svg";
import moment from "moment";

const TripManifest = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [theHiringId, setTheHiringId] = useState<any>("");
  const [isFetching, setIsFetching] = useState(false);
  const [passengerList, setPassengerList] = useState<any>([]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const hiringId: any = urlParams.get("hid");
    setTheHiringId(hiringId);

    const getPassengers = async () => {
      try {
        setIsFetching(true);
        const response = await getApi(
          `${HIRING_URL}${globalVariables.get_all_passengers
          }?filterobject={"filter": {"hiring_id": ${JSON.parse(hiringId)}}}`
        );
        console.log(response);
        if (response?.status === 200) {
          setPassengerList(response?.data?.payload);
          setIsFetching(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getPassengers();
  }, []);

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar schedulesActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full overflow-y-auto pb-20">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <button
                    className="px-3 py-2 bg-oya-ghana-red text-white rounded-[30px] flex gap-2 items-center"
                    onClick={() =>
                      navigate(`/tripDetails/${id}?hid=${theHiringId}`)
                    }
                  >
                    <ArrowLeftIcon className="w-5 h-5" />
                    Back
                  </button>
                  <p className="text-lg font-semibold">Trip Manifest</p>
                </div>
              </div>

              {/* content */}
              <div className="h-full mb-40 lg:mb-20 mt-3">
                {isFetching ? (
                  <div className="flex justify-center items-center h-full">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {passengerList?.length === 0 ? (
                      <>
                        <div className="flex flex-col justify-center items-center h-full">
                          <img
                            className="w-[100px]"
                            src={noDataImg}
                            alt="noBus"
                          />
                          <p className="text-gray-500 forn-medium mt-2">
                            There are no passengers on this bus
                          </p>
                        </div>
                      </>
                    ) : (
                      <div className="lg:grid lg:grid-cols-3 gap-4">
                        {passengerList?.map((item: any, i: number) => (
                          <div
                            key={i}
                            className="mt-2 lg:mt-0 cursor-pointer bg-white shadow-lg p-[15px] w-full border-l-[2px] border-l-oya-ghana-red rounded-[10px]"
                          >
                            <div>
                              <p className="font-medium">Booking Account Holder</p>
                              <div className="flex gap-2 items-center">
                                <UserIcon className="h5 w-5" />
                                <p>Name: {item?.user?.name}</p>
                              </div>
                              <div className="flex gap-2 items-center mt-1">
                                <PhoneIcon className="h5 w-5" />
                                <p>Contact: {item?.user?.phone}</p>
                              </div>
                            </div>
                            <hr className="my-3" />

                            <p className="font-medium">Ward Details</p>
                            <div className="flex gap-2 items-center mt-2">
                              <UserIcon className="h5 w-5" />
                              <p>Name: {item?.attendee_name}</p>
                            </div>
                            <div className="flex gap-2 items-center mt-1">
                              <PhoneIcon className="h5 w-5" />
                              <p>Contact: {item?.attendee_number}</p>
                            </div>
                            <div className="flex gap-2 items-center mt-1">
                              <PhoneIcon className="h5 w-5" />
                              <p>Emergency Contact: {item?.user?.ice1_phone}</p>
                            </div>
                            <hr className="my-3" />

                            <div className="flex gap-2 items-center mt-1">
                              <ClockIcon className="h5 w-5" />
                              <p>
                                Booking Time:{" "}
                                {moment(item?.created_at).format(
                                  "Do MMM YYYY - hh:mm A"
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripManifest;
