import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { putApi } from "../../../utils/api";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import CustomAlerts from "../../../components/general/alerts";

interface modalProps {
  openAssignBus: boolean;
  handleOpenAssignBus: () => void;
  hiringId: string;
  handleRefetch: () => void;
  oldData: any
}

const AssignBusModal = ({
  openAssignBus,
  handleOpenAssignBus,
  hiringId,
  handleRefetch, oldData
}: modalProps) => {
  const [busModel, setBusModel] = useState("");
  const [busReg, setBusReg] = useState("");
  const [busCapacity, setBusCapacity] = useState<any>("");
  const [seatCapacity, setSeatCapacity] = useState<any>("");
  const [isCreating, setIsCreating] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    console.log(oldData)
    if (oldData !== null) {
      setBusModel(oldData?.model)
      setBusReg(oldData?.registeration)
      setBusCapacity(oldData?.max_number)
      setSeatCapacity(oldData?.seat_number)
    }
  }, [oldData])

  // handle assign bus
  const assignBus = () => {
    if (
      busModel === "" ||
      busReg === "" ||
      busCapacity === "" ||
      seatCapacity === ""
    ) {
      setIsError(true);
    } else {
      let data = JSON.stringify({
        model: busModel,
        registeration: busReg,
        hiring_id: parseInt(hiringId),
        max_number: parseInt(busCapacity),
        seat_number: parseInt(seatCapacity),
      });
      setIsCreating(true);

      putApi(`${HIRING_URL}${globalVariables.assign_bus_route}`, data)
        .then((response: any) => {
          console.log(response);
          if (response.status === 200) {
            handleOpenAssignBus();
            handleRefetch();
          }
        })
        .catch((error) => {
          console.log(error);

          CustomAlerts.error_alert(
            "Error",
            "Failed to assign bus to this trip",
            "Retry"
          ).then((action) => {
            if (action.isConfirmed) {
              setIsCreating(false);
              handleOpenAssignBus();
            }
          });
        });
    }
  };

  //handleBusCapacityChange
  const handleBusCapacityChange = (e: any) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    if (isNaN(parsedValue) || parsedValue < 0) {
      setBusCapacity('');
    } else {
      setBusCapacity(parsedValue);
    }
  };
  //handleSeatCapacityChange
  const handleSeatCapacityChange = (e: any) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    if (isNaN(parsedValue) || parsedValue < 0) {
      setSeatCapacity('');
    } else {
      setSeatCapacity(parsedValue);
    }
  };

  return (
    <>
      <Dialog open={openAssignBus} handler={handleOpenAssignBus} size="sm">
        <DialogHeader>Bus Details</DialogHeader>
        <DialogBody divider>
          <div className="text-black">
            <div>
              <Input
                label="Bus Model"
                value={busModel}
                onChange={(e) => setBusModel(e.target.value)}
                error={isError}
                disabled={isCreating}
              />
            </div>
            <div className="mt-3">
              <Input
                label="Registration Number"
                value={busReg}
                onChange={(e) => setBusReg(e.target.value)}
                error={isError}
                disabled={isCreating}
              />
            </div>
            <div className="mt-3">
              <Input
                label="Passenger Capacity"
                type="number"
                min={0}
                value={busCapacity}
                onChange={handleBusCapacityChange}
                error={isError}
                disabled={isCreating}
              />
            </div>
            <div className="mt-3">
              <Input
                label="Bus Seat Capacity"
                type="number"
                min={0}
                value={seatCapacity}
                onChange={handleSeatCapacityChange}
                error={isError}
                disabled={isCreating}
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpenAssignBus}
            className="mr-1"
            disabled={isCreating}
          >
            <span>Cancel</span>
          </Button>
          <button
            className="px-3 py-2 bg-oya-ghana-red text-white rounded-[5px] disabled:bg-gray-500 disabled:cursor-not-allowed"
            onClick={() => assignBus()}
            disabled={isCreating}
          >
            {isCreating ? <Spinner /> : <span>Proceed</span>}
          </button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default AssignBusModal;
