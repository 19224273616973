import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner,
} from "@material-tailwind/react";
import { useState } from "react";
import { postApi, putApi } from "../../../utils/api";
import { AUTHMS_URL, HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import CustomAlerts from "../../../components/general/alerts";
import { removeBlankSpaces } from "../../../utils/functions";
import PhoneInput, { Value } from "react-phone-number-input";


interface modalProps {
  openAssignBus: boolean;
  handleOpenAssignBus: () => void;
  hiringId: string;
  handleRefetch: () => void;
  oldData: any
}

const AssignDriverModal = ({
  openAssignBus,
  handleOpenAssignBus,
  hiringId,
  handleRefetch, oldData
}: modalProps) => {
  const [phoneNum, setPhoneNum] = useState<Value | any | undefined>(oldData?.phone);
  const [isCreating, setIsCreating] = useState(false);
  const [isError, setIsError] = useState(false);
  const [driverNotFound, setDriverNotFound] = useState(false)


  // handle assign driver
  const assignDriver = async () => {
    if (phoneNum.length <= 5) {
      setIsError(true);
    } else {
      console.log(phoneNum)
      setIsCreating(true);
      //find driver by phone
      let data = JSON.stringify({
        "phone": removeBlankSpaces(phoneNum)
      });
      await postApi(`${AUTHMS_URL}${globalVariables.find_driver_by_phone}`, data).then((response: any) => {
        if (response?.data === undefined) {
          // if driver does not exist
          console.log("User not found")
          setIsCreating(false);
          setDriverNotFound(true)
        }
        else {
          // if driver exists
          console.log(response?.data)

          let assignData = JSON.stringify({
            "hiring_id": parseInt(hiringId),
            "phone": response?.data?.payload?.phone,
            "driver_id": response?.data?.payload?.id
          })

          putApi(`${HIRING_URL}${globalVariables.assign_driver_route}`, assignData)
            .then((response: any) => {
              console.log(response);
              if (response.status === 200) {
                handleOpenAssignBus();
                handleRefetch();
              }
            })
            .catch((error) => {
              console.log(error);

              CustomAlerts.error_alert(
                "Error",
                "Failed to assign bus to this trip",
                "Retry"
              ).then((action) => {
                if (action.isConfirmed) {
                  setIsCreating(false);
                  handleOpenAssignBus();
                }
              });
            });

        }
      })



    }
  };

  // for new account
  const [fName, setFName] = useState("")
  const [lName, setLName] = useState("")
  const [ice1, setIce1] = useState<Value | any | undefined>("")
  const [ice2, setIce2] = useState<Value | any | undefined>("")
  const [thePin, setThePin] = useState("")

  // handle create account
  const createNewDriver = async () => {
    if (phoneNum.length <= 5 || fName === "" || lName === "" || ice1.length <= 5 || ice2.length <= 5 || thePin === "") {
      setIsError(true)
    }
    else {
      let data = JSON.stringify({
        "first_name": fName,
        "last_name": lName,
        "phone": removeBlankSpaces(phoneNum),
        "ice1_phone": removeBlankSpaces(ice1),
        "ice2_phone": removeBlankSpaces(ice2),
        "country_code": "GH",
        "pin": thePin
      });
      console.log(JSON.parse(data))
      setIsCreating(true)

      await postApi(`${AUTHMS_URL}${globalVariables.register_driver}`, data).then((response: any) => {
        console.log(response)
        if (response?.data === undefined) {
          setIsCreating(false)
          CustomAlerts.error_alert("Error", "Could not create a new driver", "Retry");
          handleOpenAssignBus()
        }
        else {
          let assignData = JSON.stringify({
            "hiring_id": parseInt(hiringId),
            "phone": response?.data?.payload?.user?.phone,
            "driver_id": response?.data?.payload?.user?.id
          })

          putApi(`${HIRING_URL}${globalVariables.assign_driver_route}`, assignData)
            .then((response: any) => {
              console.log(response);
              if (response.status === 200) {
                handleOpenAssignBus();
                handleRefetch();
              }
            })
            .catch((error) => {
              console.log(error);

              CustomAlerts.error_alert(
                "Error",
                "Failed to assign bus to this trip",
                "Retry"
              ).then((action) => {
                if (action.isConfirmed) {
                  setIsCreating(false);
                  handleOpenAssignBus();
                }
              });
            });
        }
      })
        .catch((error) => {
          console.log(error)
          console.log("error occured")
        })

    }
  }

  return (
    <>
      <Dialog open={openAssignBus} handler={handleOpenAssignBus} size="sm">
        {
          driverNotFound ?
            <>
              <DialogHeader>Create New Driver</DialogHeader>
              <DialogBody divider>
                <div className="text-black">
                  <div>
                    <Input
                      label="First Name"
                      value={fName}
                      onChange={(e) => setFName(e.target.value)}
                      error={isError}
                      disabled={isCreating}
                    />
                  </div>
                  <div className="mt-3">
                    <Input
                      label="Last Name"
                      value={lName}
                      onChange={(e) => setLName(e.target.value)}
                      error={isError}
                      disabled={isCreating}
                    />
                  </div>
                  <div className="mt-3">
                    <small className={`${isError ? 'text-red-500' : ''}`}>Enter phone number</small>
                    <PhoneInput
                      className="rounded-[5px] w-full bg-white border border-gray-500 px-2 py-1"
                      defaultCountry="GH"
                      inputStyle={{ width: "100%" }}
                      value={phoneNum}
                      onChange={(phone) => setPhoneNum(phone)}
                      disabled={isCreating}
                    />
                  </div>
                  <div className="mt-3">
                    <small className={`${isError ? 'text-red-500' : ''}`}>Emergency Phone Number 1</small>
                    <PhoneInput
                      className="rounded-[5px] w-full bg-white border border-gray-500 px-2 py-1"
                      defaultCountry="GH"
                      inputStyle={{ width: "100%" }}
                      value={ice1}
                      onChange={(phone) => setIce1(phone)}
                      disabled={isCreating}
                    />
                  </div>
                  <div className="mt-3">
                    <small className={`${isError ? 'text-red-500' : ''}`}>Emergency Phone Number 2</small>
                    <PhoneInput
                      className="rounded-[5px] w-full bg-white border border-gray-500 px-2 py-1"
                      defaultCountry="GH"
                      inputStyle={{ width: "100%" }}
                      value={ice2}
                      onChange={(phone) => setIce2(phone)}
                      disabled={isCreating}
                    />
                  </div>
                  <div className="mt-3">
                    <Input
                      label="Pin"
                      value={thePin}
                      onChange={(e) => setThePin(e.target.value)}
                      error={isError}
                      disabled={isCreating}
                    />
                  </div>
                </div>
              </DialogBody>
              <DialogFooter>
                <Button
                  variant="text"
                  color="red"
                  className="mr-1"
                  onClick={handleOpenAssignBus}
                  disabled={isCreating}
                >
                  <span>Cancel</span>
                </Button>
                <button
                  className="px-3 py-2 bg-oya-ghana-red text-white rounded-[5px] disabled:bg-gray-500 disabled:cursor-not-allowed"
                  onClick={() => createNewDriver()}
                  disabled={isCreating}
                >
                  {isCreating ? <Spinner /> : <span>Create</span>}
                </button>
              </DialogFooter>
            </>
            :
            <>
              <DialogHeader>Assign Driver</DialogHeader>
              <DialogBody divider>
                <div className="text-black">
                  <small className={`${isError ? 'text-red-500' : ''}`}>Enter phone number</small>
                  <PhoneInput
                    className="rounded-[5px] w-full bg-white border border-gray-500 px-2 py-1"
                    defaultCountry="GH"
                    inputStyle={{ width: "100%" }}
                    value={phoneNum}
                    onChange={(phone) => setPhoneNum(phone)}
                    disabled={isCreating}
                  />
                </div>
              </DialogBody>
              <DialogFooter>
                <Button
                  variant="text"
                  color="red"
                  onClick={handleOpenAssignBus}
                  className="mr-1"
                  disabled={isCreating}
                >
                  <span>Cancel</span>
                </Button>
                <button
                  className="px-3 py-2 bg-oya-ghana-red text-white rounded-[5px] disabled:bg-gray-500 disabled:cursor-not-allowed"
                  onClick={() => assignDriver()}
                >
                  {isCreating ? <Spinner /> : <span>Proceed</span>}
                </button>
              </DialogFooter>
            </>
        }
      </Dialog>
    </>
  );
};

export default AssignDriverModal;
