import {
  CalendarDaysIcon,
  CpuChipIcon,
  DocumentTextIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Spinner,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import CustomAlerts from "../../../components/general/alerts";
import { getApi, postApi } from "../../../utils/api";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import moment from "moment";
import nodataImg from "../../../images/noDataImg.svg";
import CustomToasts from "../../../components/general/toasts";

interface modalProps {
  openMigrateModal: boolean;
  handleOpenMigrateModal: () => void;
  handleReFetch: () => void;
  necessaryDetails: any;
}

const MigrateUserModal = ({
  openMigrateModal,
  handleOpenMigrateModal,
  necessaryDetails, handleReFetch
}: modalProps) => {
  const [dataForModal, setDataForModal] = useState<any>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [hiringRequests, setHiringRequests] = useState([]);
  const [searchValue, setSearchValue] = useState("")
  const [searchError, setSearchError] = useState(false)
  const [searchFilter, setSearchFilter] = useState<any>(null)

  // on modal mount
  useEffect(() => {
    console.log(necessaryDetails);
    setDataForModal(necessaryDetails);
    const fetchHiringReqs = async () => {
      setIsFetching(true);
      try {
        const response = await getApi(
          `${HIRING_URL}${globalVariables.get_hiring_requests
          }?filterobject={"filter": {"source": { "id": ${parseInt(
            necessaryDetails?.sourceId
          )} },"destination": { "id": ${parseInt(
            necessaryDetails?.destinationId
          )} },"bus": { "$not": "paramIsNull" },"driver": { "$not": "paramIsNull" } ${!searchFilter ? '' : searchFilter}}}`
        );
        console.log(response?.data);
        setHiringRequests(response?.data?.payload);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchHiringReqs();
  }, [necessaryDetails, searchFilter]);

  // handle migration
  const handleMigration = (selected: any) => {
    console.log(selected);
    handleOpenMigrateModal();
    CustomAlerts.question_alert(
      "Confirm",
      `Do you want to migrate the selected user(s) to ${selected?.bus?.model} with registration number ${selected?.bus?.registeration}?`,
      "Migrate"
    ).then((result) => {
      if (result.isConfirmed) {
        CustomAlerts.loading_alert("Migrating User(s)");
        const data = {
          hiringId: parseInt(selected?.id),
          scheduleId: dataForModal["scheduleId"],
          bookingIds: dataForModal["bookingIds"],
        };
        console.log(data);

        postApi(
          `${HIRING_URL}${globalVariables.migrate_users_route}`,
          JSON.stringify(data)
        )
          .then((response) => {
            console.log(response);
            CustomToasts.success_toast(
              "User(s) has been migrated successfully"
            );
            handleReFetch()
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  //handleSearch
  const handleSearch = () => {
    if (!searchValue) {
      setSearchError(true)
    }
    else {
      setSearchFilter(`, "bus": { "registeration": ${JSON.stringify(searchValue)} }`)
    }
  }

  return (
    <>
      <Dialog
        open={openMigrateModal}
        handler={handleOpenMigrateModal}
        size="xl"
        className="overflow-scroll h-[40rem]"
      >
        <DialogHeader>
          <div className="flex justify-between items-center w-full">
            <h4>Add Users To Bus</h4>
            <XMarkIcon
              className="h-5 w-5 text-red-500 cursor-pointer"
              onClick={handleOpenMigrateModal}
            />
          </div>
        </DialogHeader>
        <DialogBody divider>
          <div className="px-[20px]">
            <label htmlFor="bus">Search Bus Registration No.</label>
            <div className="lg:w-72 flex gap-2">
              <div>
                <Input id="bus" label="GX 000 23" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} error={searchError} disabled={isFetching} />

              </div>
              <button className="h-10 w-12 flex justify-center items-center bg-oya-ghana-red text-white rounded-[3px]" onClick={() => handleSearch()} disabled={isFetching}>
                <MagnifyingGlassIcon className="h-5 w-5" />
              </button>
            </div>

            <div className="mt-3">
              {isFetching ? (
                <div className="flex justify-center items-center h-[30rem]">
                  <Spinner />
                </div>
              ) : (
                <>
                  {hiringRequests.length === 0 ? (
                    <div className="flex flex-col justify-center items-center h-[30rem]">
                      <img className="w-[100px]" src={nodataImg} alt="nodata" />
                      <p>No Buses Availbale</p>
                    </div>
                  ) : (
                    <>
                      {hiringRequests.map((item: any, i) => (
                        <div
                          key={i}
                          className="p-[15px] rounded-[10px] bg-gray-100 border-[1px] text-black cursor-pointer mt-3 hover:border-oya-ghana-red"
                          onClick={() => handleMigration(item)}
                        >
                          <div className="flex items-center justify-between">
                            <p className="font-semibold">
                              Driver: {item?.driver?.name}
                            </p>
                            <p className="font-semibold text-sm">
                              {item?.passenger_summary_data?.actual_passengers}{" "}
                              Passenger(s)
                            </p>
                          </div>
                          <div>
                            <p className="text-sm">
                              {item?.bus?.max_number} passengers expected
                            </p>
                          </div>
                          <hr className="my-2 border-gray-300" />
                          <div>
                            <div className="flex gap-3">
                              <DocumentTextIcon className="h-5 w-5 text-oya-ghana-red" />
                              <p>{item?.bus?.model}</p>
                            </div>
                            <div className="flex gap-3">
                              <CpuChipIcon className="h-5 w-5 text-oya-ghana-red" />
                              <p>{item?.bus?.registeration}</p>
                            </div>
                          </div>
                          <hr className="my-2 border-gray-300" />
                          <div className="flex justify-between items-center mt-2">
                            <div className="flex gap-2">
                              <CalendarDaysIcon className="w-5 h-5" />
                              <div>
                                <p>Start Trip</p>
                                <p className="text-gray-500 text-sm">
                                  {moment(item?.start_date).format(
                                    "Do MMM YYYY - hh:mm a"
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="flex gap-2">
                              <CalendarDaysIcon className="w-5 h-5" />
                              <div>
                                <p>End Trip</p>
                                <p className="text-gray-500 text-sm">
                                  {moment(item?.end_date).format(
                                    "Do MMM YYYY - hh:mm a"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpenMigrateModal}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default MigrateUserModal;
