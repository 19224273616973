import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Select,
  Option,
  Spinner,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { getApi, putApi } from "../../../utils/api";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import CustomAlerts from "../../../components/general/alerts";

interface modalProps {
  openTripEvents: boolean;
  handleOpenTripEvents: () => void;
  hiringId: string;
  handleRefetch: () => void;
}

const UpdateTripEvents = ({
  openTripEvents,
  handleOpenTripEvents,
  hiringId,
  handleRefetch,
}: modalProps) => {
  const [hiringStatus, setHiringStatus] = useState([]);
  const [fetchingStats, setFetchingStats] = useState(false);
  const [chosenStatus, setChosenStatus] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const getHiringStatus = async () => {
      try {
        setFetchingStats(true);
        const response = await getApi(
          `${HIRING_URL}${globalVariables.get_hiring_events}`
        );
        // console.log(response?.data);
        setHiringStatus(response?.data?.payload);
        setFetchingStats(false);
      } catch (error) {
        console.log(error);
      }
    };

    getHiringStatus();
  }, []);

  // handleStatus change
  const onStatusChange = (value: string) => {
    console.log(value);
    setChosenStatus(value);
  };

  // on update
  const handleOnUpdate = async () => {
    let data = JSON.stringify({
      status: chosenStatus,
      hiring_id: parseInt(hiringId),
    });
    setIsUpdating(true);

    try {
      const response: any = await putApi(`${HIRING_URL}${globalVariables.update_trip_status}`, data)
      console.log(response);
      if (response?.data?.statusCode === 200) {
        handleOpenTripEvents();
        handleRefetch();
      }
    } catch (error: any) {
      console.log(error);
      console.log("ye")
      handleOpenTripEvents();
      setIsUpdating(false);
      CustomAlerts.error_alert(
        "Error",
        error?.response?.data?.message,
        "Retry"
      );
    }

  };

  return (
    <>
      <Dialog open={openTripEvents} handler={handleOpenTripEvents} size="sm">
        <DialogHeader>Update Trip Status</DialogHeader>
        <DialogBody divider>
          <div className="text-black">
            <Select
              label="Trip Status"
              disabled={fetchingStats}
              value=""
              onChange={(val: any) => onStatusChange(val)}
            >
              {hiringStatus.map((dd: any, i) => (
                <Option key={i} value={dd?.name}>
                  {dd?.name}
                </Option>
              ))}
            </Select>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpenTripEvents}
            className="mr-1 disabled:cursor-not-allowed"
            disabled={isUpdating}
          >
            <span>Cancel</span>
          </Button>
          <button
            className="px-3 py-2 bg-oya-ghana-red text-white rounded-[5px] disabled:cursor-not-allowed disabled:bg-gray-500"
            onClick={() => handleOnUpdate()}
            disabled={isUpdating}
          >
            {isUpdating ? <Spinner /> : <span>Update</span>}
          </button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default UpdateTripEvents;
