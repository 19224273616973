import { ShoppingBagIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Input,
  Spinner,
  Textarea,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import Select from "react-select";
import noLuggage from "../../../images/noLuggage.svg";
import { getApi, postApi } from "../../../utils/api";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import CustomAlerts from "../../../components/general/alerts";
import { Modal, Button, DatePicker } from 'rsuite';

interface modalProps {
  openModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  handleReFetch: () => void;
}

interface Luggage {
  luggage_amount: number;
  luggage_name: string;
}

const CreateScheduleModal = ({ openModal, handleOpenModal, handleCloseModal, handleReFetch }: modalProps) => {

  const [isCreating, setIsCreating] = useState(false);
  const [triggerEmptyFields, setTriggerEmptyFields] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [allLoadingPoints, setAllLoadingPoints] = useState([]);
  const [destinationPoints, setDestinationPoints] = useState([])
  const [lugDesc, setLugDesc] = useState("");
  const [lugPrice, setLugPrice] = useState<any>("");
  const [addedLuggages, setAddedLuggages] = useState<Luggage[]>([]);
  const [sourceLocation, setSourceLocation] = useState({
    city: "",
    placeName: "",
    region: "",
    latitude: 0,
    longitude: 0,
  });
  const [sourceSelected, setsourceSelected] = useState(true)
  const [destinationLocation, setDestinationLocation] = useState({
    city: "",
    placeName: "",
    region: "",
    latitude: 0,
    longitude: 0,
  });
  const [theDescription, setTheDescription] = useState("");
  const [theAmount, setTheAmount] = useState<any>("");
  // const [isReturnable, setIsReturnable] = useState<any>(false);
  const [departDateTime, setDepartDateTime] = useState("");
  const [estDateTime, setEstDateTime] = useState<any>("");
  const [theCountryCode, setTheCountryCode] = useState<any>("");
  // const [theReturnDate, setTheReturnDate] = useState("");
  // const [returnExpDate, setReturnExpDate] = useState("");

  // on load
  useEffect(() => {
    const fetchLoadingPoints = async () => {
      setIsFetching(true);
      try {
        const response = await getApi(
          `${HIRING_URL}${globalVariables.get_loading_point_route}?filterobject={"limit":1000000}`
        );
        const newOptions = response?.data?.payload?.map(
          (item: any, i: number) => ({
            value: item,
            label: item?.location?.place_name,
          })
        );
        // console.log(newOptions);
        setAllLoadingPoints(newOptions);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchLoadingPoints();
  }, []);

  const handleCheckboxChecked = (event: any) => {
    setIsCheckboxChecked(event.target.checked);
  };

  //handle location source select
  const handleSourceSelect = (selected: any) => {
    console.log(selected);
    if (selected) {
      const { city_name, place_name, region, latitude, longitude } =
        selected?.value?.location;
      setSourceLocation({
        city: city_name,
        placeName: place_name,
        region: region,
        latitude: parseInt(latitude),
        longitude: parseInt(longitude),
      });

      runFilter(selected)
      setTheCountryCode(selected?.value?.country?.code);
      setsourceSelected(false)
    }
  };

  //filter destination
  const runFilter = (data: any) => {
    const filteredData = allLoadingPoints.filter((dd: any) => dd?.value?.id !== data?.value?.id)
    setDestinationPoints(filteredData)
    console.log(filteredData)
  }

  //handle location destination select
  const handleDestinationSelect = (selected: any) => {
    console.log(selected);
    if (selected) {
      const { city_name, place_name, region, latitude, longitude } =
        selected?.value?.location;
      setDestinationLocation({
        city: city_name,
        placeName: place_name,
        region: region,
        latitude: parseInt(latitude),
        longitude: parseInt(longitude),
      });
    }
  };

  //handle add luggage
  const addLuggage = () => {
    if (!lugPrice || !lugDesc) {
      return;
    } else {
      const newLuggage = {
        luggage_amount: parseFloat(lugPrice),
        luggage_name: lugDesc,
      };
      setAddedLuggages((prevLuggages) => [...prevLuggages, newLuggage]);
      setLugDesc("");
      setLugPrice("");
    }
  };

  //handle delete luggage
  const handleDeleteLuggage = (index: number) => {
    const updatedLuggages = addedLuggages.filter((_, i) => i !== index);
    setAddedLuggages(updatedLuggages);
  };

  // handle create schedule
  const createSchedule = async () => {
    if (sourceLocation.placeName === "" ||
      destinationLocation.placeName === "" ||
      departDateTime === "" ||
      estDateTime === "" ||
      theDescription === "" ||
      theAmount === "") { setTriggerEmptyFields(true) }
    else if (sourceLocation.placeName === destinationLocation.placeName) {
      CustomAlerts.error_alert('Error', 'Pick up and destination cannot be the same', 'Retry')
      handleCloseModal()
      setIsCreating(false);
    }
    else {
      const data = JSON.stringify({
        schedule_type: "HSHS",
        source_location: sourceLocation,
        drop_points: [],
        destination_location: destinationLocation,
        departure: new Date(departDateTime.toString()),
        estimated_arrival: new Date(estDateTime.toString()),
        description: theDescription,
        luggage: addedLuggages.length === 0 ? [] : addedLuggages,
        amount: parseInt(theAmount),
        country: theCountryCode,
        returnable: false,
        return_amount: null,
        return_date: null,
        return_expiry: null,
      });

      console.log(JSON.parse(data));

      try {
        setIsCreating(true);
        const response: any = await postApi(
          `${HIRING_URL}${globalVariables.create_schedule_route}`,
          data
        );
        console.log("createResponse:", response);
        if (response?.data?.statusCode === 200) {
          setIsCreating(false);
          handleCloseModal()
          handleReFetch();
        }
      } catch (error: any) {
        setIsCreating(false);
        CustomAlerts.error_alert('Error', error?.response?.data?.message, 'Retry')
        handleCloseModal()
        console.log(error);
      }
    }
  };

  //handleAmountChange
  const handleAmountChange = (e: any) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    if (isNaN(parsedValue) || parsedValue < 0) {
      setTheAmount('');
    } else {
      setTheAmount(parsedValue);
    }
  };

  //handleLugPriceChange
  const handleLugPriceChange = (e: any) => {
    const newValue = e.target.value;
    const parsedValue = parseFloat(newValue);

    if (isNaN(parsedValue) || parsedValue < 0) {
      setLugPrice('');
    } else {
      setLugPrice(parsedValue);
    }
  };

  return (
    <>
      <Modal backdrop="static" keyboard={false} open={openModal} onClose={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>
            <p className="font-semibold text-lg">Create Schedule</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="pr-3">
            {/* locations */}
            <div>
              <div className="text-black">
                <div className="flex gap-2 items-center">
                  <p className="text-lg">PickUp Location</p>
                  {triggerEmptyFields && sourceLocation.placeName === "" && (
                    <small className="text-red-500">
                      Select a pickup location
                    </small>
                  )}
                </div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Search PickUp Location"
                  isClearable={true}
                  isSearchable={true}
                  options={allLoadingPoints}
                  isLoading={isFetching}
                  isDisabled={isFetching}
                  onChange={(newValue) => handleSourceSelect(newValue)}
                />
              </div>
              <div className="text-black mt-5">
                <div className="flex gap-2 items-center">
                  <p className="text-lg">Destination Location</p>
                  {triggerEmptyFields && destinationLocation.placeName === "" && (
                    <small className="text-red-500">Select a destination</small>
                  )}
                </div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Search Destination"
                  isClearable={true}
                  isSearchable={true}
                  options={destinationPoints}
                  isLoading={isFetching}
                  isDisabled={sourceSelected}
                  onChange={(newValue) => handleDestinationSelect(newValue)}
                />
              </div>
            </div>

            <hr className="mt-10" />

            {/* luggage */}
            <div className="mt-[30px]">
              <p className="text-xl">Luggage Allowances & Pricing</p>
              <div className="text-black mt-4">
                <div className="flex justify-between items-center">
                  <p className="text-gray-500 w-[400px]">
                    You have not recorded any luggage description. Check the box
                    to add some or you can skip this.
                  </p>
                  <input
                    className="h-[20px] w-[20px]"
                    type="checkbox"
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChecked}
                  />
                </div>

                {isCheckboxChecked ? (
                  <div>
                    {addedLuggages.map((item, i) => (
                      <div
                        key={i}
                        className="p-[10px] flex justify-between bg-white shadow-md mb-2"
                      >
                        <div className="flex gap-3">
                          <ShoppingBagIcon className="w-5 h-5 text-gray-500" />
                          <div>
                            <p className="text-sm">{item?.luggage_name}</p>
                            <p className="text-sm">GH¢ {item?.luggage_amount}</p>
                          </div>
                        </div>
                        <TrashIcon
                          className="h-5 w-5 text-red-500 cursor-pointer"
                          onClick={() => handleDeleteLuggage(i)}
                        />
                      </div>
                    ))}
                    <div className="mt-4">
                      <div className="flex gap-5 justify-between">
                        <Input
                          label="Luggage description"
                          value={lugDesc}
                          onChange={(e) => setLugDesc(e.target.value)}
                          error={lugDesc === ""}
                        />
                        <Input
                          label="Price"
                          type="number"
                          min={0}
                          value={lugPrice}
                          onChange={handleLugPriceChange}
                          error={lugPrice === ""}
                        />
                      </div>
                      <div className="flex justify-end mt-2">
                        <button
                          className="px-3 py-1 bg-oya-ghana-red text-white rounded-[5px]"
                          onClick={() => addLuggage()}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center flex-col mt-2">
                    <img className="w-[120px]" src={noLuggage} alt="noLug" />
                    <p className="text-sm text-gray-500">No luggages added</p>
                  </div>
                )}
              </div>
            </div>

            <hr className="mt-10" />

            {/* schedule details */}
            <div className="mt-[30px] relative">
              <p className="text-xl">Schedule Details</p>
              <div className="text-black">
                <p className={`${triggerEmptyFields ? 'text-red-500' : ''} text-sm mb-2`}>Choose departure date and time</p>
                <DatePicker
                  format="dd MMM yyyy hh:mm aa"
                  showMeridian
                  className="w-full"
                  onChange={(date: any) => setDepartDateTime(date)}
                />
              </div>
              <div className="text-black mt-4">
                <p className={`${triggerEmptyFields ? 'text-red-500' : ''} text-sm mb-2`}>Choose estimated arrival date and time</p>
                <DatePicker
                  format="dd MMM yyyy hh:mm aa"
                  showMeridian
                  className="w-full"
                  onChange={(date: any) => setEstDateTime(date)}
                  disabled={!departDateTime}
                  shouldDisableDate={(date) => {
                    return date < new Date(departDateTime)
                  }}
                />
              </div>
              <div className="text-black mt-4">
                <p className="text-lg mb-2">Purpose or description</p>
                <Textarea
                  className="w-full border-[1px] border-gray-500 rounded-[8px] p-2 focus:outline-none"
                  label="Enter schedule purpose/description"
                  value={theDescription}
                  onChange={(e) => setTheDescription(e.target.value)}
                  error={theDescription === "" && triggerEmptyFields}
                />
              </div>
              <div className="text-black mt-2">
                <p className="text-lg mb-2">Fare</p>
                <Input
                  label="Enter Amount"
                  min={0}
                  type="number"
                  value={theAmount}
                  onChange={handleAmountChange}
                  error={theAmount === "" && triggerEmptyFields}
                />
              </div>
              {/* <div className="text-black mt-4 flex gap-[50px]">
              <div className="flex gap-3 items-center">
                <input
                  className="h-[20px] w-[20px]"
                  type="radio"
                  name="tripType"
                  value={isReturnable}
                  onChange={() => setIsReturnable(false)}
                />
                <p className="text-lg">One Way Trip</p>
              </div>
              <div className="flex gap-3 items-center">
                <input
                  className="h-[20px] w-[20px]"
                  type="radio"
                  name="tripType"
                  value={isReturnable}
                  onChange={() => setIsReturnable(true)}
                />
                <p className="text-lg">Return Trip</p>
              </div>
            </div> */}

              {/* return date */}
              <>
                {/* <div className="text-black mt-3">
                  <p className="text-lg mb-2">Choose return date and time</p>
                  <Input
                    label="Return Date"
                    type="datetime-local"
                    value={theReturnDate}
                    onChange={(e) => setTheReturnDate(e.target.value)}
                    error={!theReturnDate && triggerEmptyFields}
                  />
                </div>
                <div className="text-black mt-4">
                  <p className="text-lg mb-2">
                    Choose return expiry date and time
                  </p>
                  <Input
                    label="Expiry Date"
                    type="datetime-local"
                    value={returnExpDate}
                    onChange={(e) => setReturnExpDate(e.target.value)}
                    min={theReturnDate}
                    error={!returnExpDate && triggerEmptyFields}
                    disabled={!theReturnDate}
                  />
                </div> */}
              </>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} appearance="subtle">
            Cancel
          </Button>
          <Button onClick={() => createSchedule()} appearance="primary" color="green">
            {isCreating ? <Spinner /> : <span>Create Schedule</span>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateScheduleModal;
