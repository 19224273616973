import { getCookie } from "../helpers/utils";
import axios from "axios";

export const apiHeaders = (type = "") => {
  const token = getCookie("oya_token") || "";
  if (type === "file") {
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }
};

export const postApi = async (url: string, data: any) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: url,
    headers: apiHeaders(),
    data: data,
  };

  try {
    return await axios.request(config);
  } catch (error) {
    return error;
  }
};

export const getApi = async (url: string) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: apiHeaders(),
  };

  try {
    return await axios.request(config);
  } catch (error) {
    console.log(error);
  }
};

export const putApi = async (url: any, data: any) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: url,
    headers: apiHeaders(),
    data: data,
  };

  try {
    return await axios.request(config);
  } catch (error) {
    return error;
  }
};
