import { Input, Modal, Spin, notification } from 'antd';
import { useState } from 'react';
import { base_hshs_requests } from '../../../helpers/api';
import { RouteConstants } from '../../../helpers/RouteConstants';
import PhoneInput, { Value } from "react-phone-number-input";


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}

const CreateConductorModal = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [theName, setTheName] = useState("")
    const [phoneNum, setPhoneNum] = useState<Value>("")
    const [staffNum, setStaffNum] = useState("")


    //handleCreateConductor
    const handleCreateConductor = async () => {
        if (!theName || !phoneNum || !staffNum) {
            notification.warning({ message: "Please fill out all fields" })
        }
        else {
            let data = {
                "name": theName,
                "phone": phoneNum,
                "employee_number": staffNum
            }

            setIsBusy(true)
            try {
                const response = await base_hshs_requests.post(RouteConstants.ALL_CONDUCTORS, data)
                console.log(response?.data)
                notification.success({ message: "New conductor was created successfully" })
                setIsBusy(false)
                handleClose()
                handleReFetch()
            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.message })
            }
        }
    }

    return (
        <>
            <Modal title="Create New Conductor" open={isOpened} footer={null} keyboard={false} closeIcon={false}>
                <Spin spinning={isBusy}>
                    <hr />
                    <div>
                        <small className='mb-2'>Enter conductor's name</small>
                        <Input className='h-[40px]' value={theName} onChange={(e) => setTheName(e.target.value)} placeholder='E.g: John Doe' />

                        <div className="mt-3">
                            <small className='mb-2'>Enter phone number</small>
                            <PhoneInput
                                className="rounded-[5px] w-full bg-white border border-gray-500 px-2 py-1"
                                defaultCountry="GH"
                                inputStyle={{ width: "100%" }}
                                value={phoneNum}
                                onChange={(phone: any) => setPhoneNum(phone)}
                                placeholder="+23300000000"
                                numberInputProps={{
                                    className: "focus:outline-none"
                                }}
                            />
                        </div>

                        <div className='mt-3'>
                            <small className='mb-2'>Enter employee number</small>
                            <Input className='h-[40px]' value={staffNum} onChange={(e) => setStaffNum(e.target.value)} placeholder='E.g: OYA678945123' />
                        </div>

                        <div className="mt-4 flex justify-end gap-3">
                            <button className='px-4 py-2 border-[1px] border-gray-400 rounded-[5px]' onClick={handleClose}>
                                Cancel
                            </button>
                            <button className='px-4 py-2 bg-oya-ghana-red text-white rounded-[5px]' onClick={handleCreateConductor}>
                                Submit
                            </button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default CreateConductorModal
