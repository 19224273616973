import {
  CalendarDaysIcon,
  ChevronRightIcon,
  ClipboardDocumentCheckIcon,
  MapIcon,
  MapPinIcon,
  StopIcon,
} from "@heroicons/react/24/outline";
import CustomNavbar from "../../components/layouts/navbar";
import CustomSideBar from "../../components/layouts/sidebar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { HIRING_URL } from "../../helpers/constants";
import { globalVariables } from "../../helpers/globarVars";
import moment from "moment";
import GetSchedulesShimmer from "./schedules/getSchedulesShimmer";
import { base_hshs_requests } from "../../helpers/api";

const HSHHomePage = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [allSchedules, setAllSchdules] = useState([]);

  // fetch schedules points
  useEffect(() => {
    const fetchSchedules = async () => {
      setIsFetching(true);
      try {
        const response = await base_hshs_requests.get(
          `${HIRING_URL}${globalVariables.get_schedules_route}?filterobject={"limit":6, "order": { "updated_at": "DESC" }}`
        );
        console.log(response);
        setAllSchdules(response?.data?.payload);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSchedules();
  }, []);

  // handle on schedule click
  const handleOnScheduleClick = (data: any) => {
    // console.log(data?.id);
    navigate(`/scheduleDetails/${data?.id}`);
  };

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar homeActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            {/* page content */}
            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full overflow-auto">
              <div className="lg:grid lg:grid-cols-4 gap-4">
                <div
                  className="h-[120px] bg-white shadow-lg rounded-[10px] mt-4 lg:mt-0 cursor-pointer hover:scale-105 transition-transform duration-300"
                  onClick={() => navigate("/allScehdules")}
                >
                  <div className="flex flex-col justify-center items-center h-full">
                    <ClipboardDocumentCheckIcon className="w-8 h-8 text-oya-ghana-red" />
                    <p className="mt-2 font-medium">All Scheduled Trips</p>
                  </div>
                </div>

                <div
                  onClick={() => navigate("/loadingPoints")}
                  className="h-[120px] bg-white shadow-lg rounded-[10px] mt-4 lg:mt-0 cursor-pointer hover:scale-105 transition-transform duration-300"
                >
                  <div className="flex flex-col justify-center items-center h-full">
                    <MapIcon className="w-8 h-8 text-oya-ghana-red" />
                    <p className="mt-2 font-medium">Loading Points</p>
                  </div>
                </div>

                {/* <div className="h-[120px] bg-white shadow-lg rounded-[10px] mt-4 lg:mt-0 cursor-pointer hover:scale-105 transition-transform duration-300">
                  <div className="flex flex-col justify-center items-center h-full">
                    <ClipboardDocumentListIcon className="w-8 h-8 text-oya-ghana-red" />
                    <p className="mt-2 font-medium">Hiring Requests</p>
                  </div>
                </div> */}
              </div>

              <div className="mt-5 mb-20">
                <div className="flex justify-between items-center">
                  <p className="font-medium text-lg">Scheduled Trips</p>
                  <button
                    className="flex gap-2 items-center text-oya-ghana-red"
                    onClick={() => navigate("/allScehdules")}
                  >
                    See All
                    <ChevronRightIcon className="h-5 w-5" />
                  </button>
                </div>

                <div className="mt-5 lg:grid lg:grid-cols-3 gap-4 mb-20">
                  {isFetching ? (
                    <>
                      {["", "", "", "", "", "", "", "", ""].map((item, i) => (
                        <GetSchedulesShimmer key={i} />
                      ))}
                    </>
                  ) : (
                    <>
                      {allSchedules.map((item: any, i) => (
                        <div
                          key={i}
                          className="p-[15px] rounded-[10px] shadow-lg bg-white cursor-pointer border-l-[2px] border-l-oya-ghana-red mt-3 lg:mt-0"
                          onClick={() => handleOnScheduleClick(item)}
                        >
                          <div className="flex justify-between items-center">
                            <p><span className="font-medium">Created On: </span>
                              {moment(item?.created_at).format(
                                "Do MMM YYYY - hh:mm a"
                              )}
                            </p>
                            <p className="flex justify-end font-medium">
                              Fare:{" "}
                              {`${item?.country?.currency_code} ${item?.amount}`}
                            </p>
                          </div>
                          <hr />
                          <div className="flex gap-3 items-center mt-3">
                            <StopIcon className="h-4 w-4 text-oya-ghana-red" />
                            <p className="truncate w-[230px]">
                              {item?.source?.place_name}
                            </p>
                          </div>
                          <div className="h-[20px] border-l-[1px] border-dashed border-l-oya-ghana-red ml-[7px]"></div>
                          <div className="flex gap-3 items-center mb-3">
                            <MapPinIcon className="h-4 w-4 text-oya-ghana-red" />
                            <p className="truncate w-[230px]">
                              {item?.destination?.place_name}
                            </p>
                          </div>

                          <div>
                            <p>Created By: <span>{item?.facilitator?.name}</span></p>
                          </div>

                          <hr />
                          <div className="flex justify-between items-center mt-2">
                            <div className="flex gap-2 items-center">
                              <CalendarDaysIcon className="w-5 h-5" />
                              <div>
                                <p>Start Trip</p>
                                <p className="text-gray-500 text-sm">
                                  {moment(item?.departure).format(
                                    "Do MMM YYYY - hh:mm a"
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <CalendarDaysIcon className="w-5 h-5" />
                              <div>
                                <p>Estimated Arrival</p>
                                <p className="text-gray-500 text-sm">
                                  {moment(item?.estimated_arrival).format(
                                    "Do MMM YYYY - hh:mm a"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HSHHomePage;
