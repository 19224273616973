import { useEffect, useState } from "react";
import { ACCOUNTS_URL } from "../helpers/constants";
import { getCookie } from "../helpers/utils";
import { getBranchRoles } from "../functions/getBranchRoles";
import { Spinner } from "@material-tailwind/react";



const ProtectedRoutes = ({ children }: any) => {
  const token = getCookie("oya_token");
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isChecking, setIsChecking] = useState(true)

  useEffect(() => {
    setIsChecking(true)
    getBranchRoles().then((assignedRoles) => {
      // console.log(assignedRoles)
      // Check if the user has an authorized role
      const authorizedRoles = ["HSHS_MANAGER"];

      const hasAuthorizedRole = assignedRoles
        .map((user: any) => authorizedRoles.includes(user?.account_type?.code))
        .includes(true);

      if (hasAuthorizedRole) {
        console.log("Authorized user ");
        setIsChecking(false)
        setIsAuthorized(true);
      } else {
        console.log("Unauthorized user");
        setIsChecking(false)
        setIsAuthorized(false);
      }
    })

    // eslint-disable-next-line
  }, [])

  if (token) {
    if (isChecking) {
      return <div className="flex justify-center items-center h-screen">
        <Spinner className="h-10 w-10" />
      </div>
    }
    else {
      if (isAuthorized) {
        return children;
      }
      else {
        window.location.href = "/unauthorized"
        console.log(false)
      }
    }
  } else {
    return (window.location.href = `${ACCOUNTS_URL}/login?appref=${window.location.href}`);
  }
};

export default ProtectedRoutes;
