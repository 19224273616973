// api.js
import axios from "axios";
import { getCookie } from "./utils";
import { AUTHMS_URL, HIRING_URL, HSHS_URL, RIDE_MS_URL } from "./constants";

const token = getCookie("oya_token");

export const base_hshs_requests = axios.create({
  baseURL: `${HSHS_URL}`,

  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const base_auth_requests = axios.create({
  baseURL: `${AUTHMS_URL}`,

  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const base_hiring_requests = axios.create({
  baseURL: `${HIRING_URL}`,

  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const base_ride_ms_requests = axios.create({
  baseURL: `${RIDE_MS_URL}`,

  headers: {
    Authorization: `Bearer ${token}`,
  },
});
