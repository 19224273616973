import {
  ArrowLeftIcon,
  ArrowPathIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomNavbar from "../../../components/layouts/navbar";
import CustomSideBar from "../../../components/layouts/sidebar";
import noDataImg from "../../../images/noDataImg.svg";
import { getApi } from "../../../utils/api";
import { globalVariables } from "../../../helpers/globarVars";
import { HIRING_URL } from "../../../helpers/constants";
import moment from "moment";
import GetSchedulesShimmer from "./getSchedulesShimmer";

const ViewLoadedBuses = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [loadedBuses, setLoadedBuses] = useState([]);

  useEffect(() => {
    const fetchLoadedBuses = async () => {
      try {
        setIsFetching(true);
        const response = await getApi(
          `${HIRING_URL}${globalVariables.get_single_schedule}?id=${id}`
        );
        console.log(response?.data);
        setLoadedBuses(response?.data?.payload?.hirings);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLoadedBuses();
  }, [id, reFetch]);

  // on bus clicked
  const handleOnBusClicked = (selected: any) => {
    console.log(selected);
    navigate(`/tripDetails/${id}?hid=${selected?.id}`);
  };

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar schedulesActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full overflow-y-auto pb-20">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <button
                    className="px-3 py-2 bg-oya-ghana-red text-white rounded-[30px] flex gap-2 items-center"
                    onClick={() => navigate(`/scheduleDetails/${id}`)}
                  >
                    <ArrowLeftIcon className="w-5 h-5" />
                    Back
                  </button>
                  <p className="text-lg font-semibold">Schedule Buses</p>
                </div>
                <button
                  className="px-3 lg:py-2 py-3 bg-oya-ghana-red text-white rounded-full lg:rounded-[30px] flex gap-2 items-center"
                  onClick={() => setReFetch(!reFetch)}
                >
                  <ArrowPathIcon className="w-5 h-5" />
                  <p className="hidden lg:block">Refresh</p>
                </button>
              </div>

              {/* content */}
              <div className="h-full">
                {isFetching ? (
                  <div className="mt-3">
                    <GetSchedulesShimmer />
                  </div>
                ) : (
                  <>
                    {loadedBuses.length === 0 ? (
                      <div className="flex flex-col justify-center items-center h-full">
                        <img
                          className="w-[100px]"
                          src={noDataImg}
                          alt="noBus"
                        />
                        <p className="text-gray-500 forn-medium mt-2">
                          No busses relating to this schedule...
                        </p>
                      </div>
                    ) : (
                      <>
                        {loadedBuses.map((item: any, i: number) => (
                          <div
                            key={i}
                            className="p-[15px] rounded-[10px] shadow-lg bg-white border-l-[2px] border-l-oya-ghana-red mt-3 cursor-pointer"
                            onClick={() => handleOnBusClicked(item)}
                          >
                            <div className="flex justify-between items-center">
                              <p className="font-semibold">
                                Driver: {item?.driver?.name}
                              </p>
                              <p className="font-semibold text-sm">
                                {
                                  item?.passenger_summary_data
                                    ?.actual_passengers
                                }{" "}
                                passengers
                              </p>
                            </div>
                            <p className="text-sm">
                              {item?.passenger_summary_data?.max_passengers}{" "}
                              passengers expected
                            </p>
                            <hr />
                            <div className="flex gap-3 items-center mt-3">
                              <p className="font-semibold">Bus Model:</p>
                              <p className="truncate lg:w-full w-[230px]">
                                {item?.bus?.model}
                              </p>
                            </div>
                            <div className="flex gap-3 items-center mb-3">
                              <p className="font-semibold">Bus No.:</p>
                              <p className="truncate lg:w-full w-[230px]">
                                {item?.bus?.registeration}
                              </p>
                            </div>
                            <hr />
                            <div className="flex justify-between items-center mt-2">
                              <div className="flex gap-2 items-center">
                                <CalendarDaysIcon className="w-5 h-5" />
                                <div>
                                  <p>Start Trip</p>
                                  <p className="text-gray-500 text-sm">
                                    {moment(item?.start_date).format(
                                      "Do MMM YYYY - hh:mm a"
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="flex gap-2 items-center">
                                <CalendarDaysIcon className="w-5 h-5" />
                                <div>
                                  <p>End Trip</p>
                                  <p className="text-gray-500 text-sm">
                                    {moment(item?.end_date).format(
                                      "Do MMM YYYY - hh:mm a"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewLoadedBuses;
