import {
  ArrowLeftIcon,
  ArrowPathIcon,
  CalendarDaysIcon,
  MapPinIcon,
  PlusIcon,
  StopIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomNavbar from "../../../components/layouts/navbar";
import CustomSideBar from "../../../components/layouts/sidebar";
import noDataImg from "../../../images/noDataImg.svg";
import { getApi } from "../../../utils/api";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import { Option, Select, Spinner } from "@material-tailwind/react";
import CreateHiringReqModal from "./createHiringReqModal";
import moment from "moment";

const ViewAssignedBuses = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [hiringStatus, setHiringStatus] = useState([]);
  const [fetchingStats, setFetchingStats] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [assignedBuses, setAssignedBuses] = useState([]);
  const [sourceLocation, setsourceLocation] = useState<any>("");
  const [destinationLocation, setDestinationLocation] = useState<any>("");
  const [theTripType, setTheTripType] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState("Pending")

  const [openCreateHiringReq, setOpenCreateHiringReq] = useState(false);
  const handleOpenCreateHiringReq = () =>
    setOpenCreateHiringReq(!openCreateHiringReq);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const srcValue = urlParams.get("src");
    const desValue = urlParams.get("des");
    const tripType = urlParams.get("type");
    setsourceLocation(srcValue);
    setDestinationLocation(desValue);
    setTheTripType(tripType);

    fetchAssignedBuses(srcValue, desValue, selectedStatus);
    getHiringStatus();
  }, [id, reFetch, selectedStatus]);

  // fetch assigned Busses
  const fetchAssignedBuses = async (source: any, destination: any, filterStatus: any) => {
    try {
      setIsFetching(true);
      const response = await getApi(
        `${HIRING_URL}${globalVariables.get_hiring_requests
        }?filterobject={"filter": {"source": { "place_name": ${JSON.stringify(
          source
        )} },"destination": {"place_name": ${JSON.stringify(
          destination
        )}},"current_status": { "name": ${JSON.stringify(filterStatus)} },"schedule_type": { "code": "HSHS" }}}`
      );
      console.log(response?.data);
      setAssignedBuses(response?.data?.payload);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  //handle filter status
  const onFilterStatusChanged = (selected: any) => {
    console.log(selected)
    setSelectedStatus(selected)
  }

  // get hiring status
  const getHiringStatus = async () => {
    try {
      setFetchingStats(true);
      const response = await getApi(
        `${HIRING_URL}${globalVariables.get_hiring_events}`
      );
      // console.log(response?.data);
      setHiringStatus(response?.data?.payload);
      setFetchingStats(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTripSelect = (data: any) => {
    // console.log(data);
    navigate(`/tripDetails/${id}?hid=${data?.id}`);
  };

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar schedulesActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full overflow-y-auto pb-20">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <button
                    className="px-3 py-2 bg-oya-ghana-red text-white rounded-[30px] flex gap-2 items-center"
                    onClick={() => navigate(`/scheduleDetails/${id}`)}
                  >
                    <ArrowLeftIcon className="w-5 h-5" />
                    Back
                  </button>
                  <p className="text-lg font-semibold">Assigned Buses</p>
                </div>
                <button
                  className="h-[40px] w-[40px] bg-oya-ghana-red text-white rounded-full lg:rounded-[30px] flex justify-center items-center"
                  onClick={() => setReFetch(!reFetch)}
                >
                  <ArrowPathIcon className="w-5 h-5" />
                </button>
              </div>

              <div className="mt-4 flex items-center gap-3">
                <div className="lg:w-[300px]">
                  <Select label="Trip Status" disabled={fetchingStats} onChange={(val) => onFilterStatusChanged(val)}>
                    {hiringStatus.map((dd: any, i) => (
                      <Option key={i} value={dd?.name}>
                        {dd?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <button
                  className="h-[40px] w-[40px] rounded-full bg-oya-ghana-red text-white flex justify-center items-center"
                  onClick={() => handleOpenCreateHiringReq()}
                >
                  <PlusIcon className="w-5 h-5" />
                </button>
              </div>

              {/* content */}
              <div className="h-full">
                {isFetching ? (
                  <div className="flex flex-col justify-center items-center h-full">
                    <Spinner />
                  </div>
                ) : assignedBuses.length === 0 ? (
                  <div className="flex flex-col justify-center items-center h-full">
                    <img className="w-[100px]" src={noDataImg} alt="noBus" />
                    <p className="text-gray-500 forn-medium mt-2">
                      No busses relating to this schedule...
                    </p>
                  </div>
                ) : (
                  <div className="lg:grid lg:grid-cols-2 gap-4">
                    {assignedBuses.map((item: any, i) => (
                      <div
                        key={i}
                        className="p-[15px] rounded-[10px] shadow-lg bg-white border-l-[2px] border-l-oya-ghana-red mt-3 cursor-pointer"
                        onClick={() => handleTripSelect(item)}
                      >
                        <div className="flex justify-between items-center">
                          {item?.bus ? <p className="font-semibold">{item?.bus?.model} - {item?.bus?.registeration}</p> : <p className="font-semibold">No Bus Assigned</p>}
                          <p className="font-semibold text-sm">
                            {item?.current_status?.name}
                          </p>
                        </div>
                        <p className="text-sm">
                          {item?.passenger_summary_data?.max_passengers} to{" "}
                          {item?.passenger_summary_data?.min_passengers}{" "}
                          expected,{" "}
                          {item?.passenger_summary_data?.actual_passengers}{" "}
                          onboarded
                        </p>
                        <hr />
                        <div className="flex gap-3 items-center mt-3">
                          <StopIcon className="h-4 w-4 text-oya-ghana-red" />
                          <p className="truncate lg:w-full w-[230px]">
                            {item?.source?.place_name}
                          </p>
                        </div>
                        <div className="h-[20px] border-l-[1px] border-dashed border-l-oya-ghana-red ml-[7px]"></div>
                        <div className="flex gap-3 items-center mb-3">
                          <MapPinIcon className="h-4 w-4 text-oya-ghana-red" />
                          <p className="truncate lg:w-full w-[230px]">
                            {item?.destination?.place_name}
                          </p>
                        </div>

                        <div>
                          <p>Assigned By: <span>{item?.creator?.name}</span></p>
                        </div>

                        <hr />
                        <div className="flex justify-between items-center mt-2">
                          <div className="flex gap-2 items-center">
                            <CalendarDaysIcon className="w-5 h-5" />
                            <div>
                              <p>Start Trip</p>
                              <p className="text-gray-500 text-sm">
                                {moment(item?.start_date).format(
                                  "Do MMM YYYY - hh:mm a"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <CalendarDaysIcon className="w-5 h-5" />
                            <div>
                              <p>End Trip</p>
                              <p className="text-gray-500 text-sm">
                                {moment(item?.end_date).format(
                                  "Do MMM YYYY - hh:mm a"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* create hiring request modal */}
      {openCreateHiringReq ? (
        <CreateHiringReqModal
          isModOpen={openCreateHiringReq}
          handleIsModOpen={handleOpenCreateHiringReq}
          srcLocation={sourceLocation}
          desLocation={destinationLocation}
          tripType={theTripType}
          hiringData={assignedBuses}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ViewAssignedBuses;
