import {
  ArrowLeftIcon,
  CalendarDaysIcon,
  EyeIcon,
  MapPinIcon,
  PlusIcon,
  StopIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo, useCallback } from "react";
import CreateScheduleModal from "./createScheduleModal";
import CustomNavbar from "../../../components/layouts/navbar";
import CustomSideBar from "../../../components/layouts/sidebar";
import { HIRING_URL } from "../../../helpers/constants";
import { globalVariables } from "../../../helpers/globarVars";
import moment from "moment";
import GetSchedulesShimmer from "./getSchedulesShimmer";
import MaterialReactTable from "material-react-table";
import { base_hshs_requests } from "../../../helpers/api";

const AllScehdules = () => {
  const navigate = useNavigate();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [reFetch, setReFetch] = useState(false)
  const [isFetching, setIsFetching] = useState(false);
  const [allSchedules, setAllSchdules] = useState([]);
  const [schedulesDesktop, setSchedulesDesktop] = useState([])
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [payloadSkip, setPayloadSkip] = useState(0);
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0
  });
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0
  });


  // useEffect to check the screen size on initial render
  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  // fetch schedules points
  useEffect(() => {
    if (isMobile) {
      const fetchSchedules = async () => {
        setIsFetching(true);
        try {
          const response = await base_hshs_requests.get(
            `${HIRING_URL}${globalVariables.get_schedules_route
            }?filterobject=${JSON.stringify({
              ...filters,
              order: { updated_at: "DESC" },
            })}`
          );
          console.log(response?.data);
          setAllSchdules(response?.data?.payload);
          setTotal(response?.data?.total);
          setPayloadSkip(response?.data?.skip);
          setIsFetching(false);
        } catch (error) {
          console.log(error);
        }
      };

      fetchSchedules();
    }
    else {
      //for desktop
      const fetchForDesktop = async () => {
        setIsFetching(true);
        try {
          const response = await base_hshs_requests.get(
            `${HIRING_URL}${globalVariables.get_schedules_route
            }?filterobject={"limit":${JSON.stringify(pagination.pageSize)}, "skip":${JSON.stringify(pagination.pageIndex * pagination.pageSize)},"order":{"updated_at":"DESC"}}`
          );
          console.log(response?.data);
          setSchedulesDesktop(response?.data?.payload);
          setTotal(response?.data?.total);
          setPayloadSkip(response?.data?.skip);
          setIsFetching(false);
        } catch (error) {
          console.log(error);
        }
      }
      fetchForDesktop()
    }
  }, [filters, reFetch, isMobile, pagination.pageIndex, pagination.pageSize]);

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };
  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  // pagination logic start
  const handleNext = () => {
    if (page >= 1) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        limit: 10,
        skip: prevFilters.limit * page,
      }));
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        limit: 10,
        skip: prevFilters.skip - prevFilters.limit,
      }));
      setPage((prevPage) => prevPage - 1);
    }
  };
  // pagination logic end

  // handle on schedule click
  const handleOnScheduleClick = useCallback((data: any) => {
    // console.log(data?.id);
    navigate(`/scheduleDetails/${data?.id}`);
  }, [navigate])

  //refetch handle
  const handlereFetch = () => {
    setReFetch(!reFetch)
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.source?.place_name,
        id: "source",
        header: "Source",
      },
      {
        accessorFn: (row: any) => row?.destination?.place_name,
        id: "destination",
        header: "Destination",
      },
      {
        accessorFn: (row: any) => moment(row?.departure).format("Do MMM YYYY hh:mm a"),
        id: "dep",
        header: "Departure",
      },
      {
        accessorFn: (row: any) => moment(row?.estimated_arrival).format("Do MMM YYYY hh:mm a"),
        id: "est",
        header: "Estimated Arrival",
      },
      {
        accessorFn: (row: any) => `${row?.country?.currency_code} ${row?.amount}`,
        id: "fare",
        header: "Fare",
      },
      {
        accessorFn: (row: any) => `${row?.facilitator ? row?.facilitator?.name : ''}`,
        id: "created",
        header: "Created By",
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY hh:mm a"),
        id: "date",
        header: "Date Created",
      },
      {
        accessorFn: (row: any) =>
          <button onClick={() => handleOnScheduleClick(row)}>
            <EyeIcon className="w-5 h-5 cursor-pointer" />
          </button>,
        id: "act",
        header: "Action",
      },
    ],
    [handleOnScheduleClick]
  );


  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex h-full overflow-hidden">
          {/* sidebar */}
          <CustomSideBar schedulesActive="active" />
          {/* navbar */}
          <div className="w-full h-full">
            <CustomNavbar />

            <div className="p-[10px] lg:p-[20px] bg-[#7c000008] h-full overflow-auto pb-20">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <button
                    className="px-3 py-2 bg-oya-ghana-red text-white rounded-[30px] flex gap-2 items-center"
                    onClick={() => navigate("/")}
                  >
                    <ArrowLeftIcon className="w-5 h-5" />
                    Back
                  </button>
                  <p className="text-lg font-semibold">Scheduled Trips</p>
                </div>
                <button
                  className="px-3 lg:py-2 py-3 bg-oya-ghana-red text-white rounded-full lg:rounded-[30px] flex gap-2 items-center"
                  onClick={() => handleOpenCreateModal()}
                >
                  <PlusIcon className="w-5 h-5" />
                  <p className="hidden lg:block">Create New</p>
                </button>
              </div>

              {/* for mobile or desktop */}
              {
                isMobile ?
                  <>
                    <div className="mt-5 lg:grid lg:grid-cols-3 gap-4 mb-20">
                      {isFetching ? (
                        <>
                          {["", "", "", "", "", "", "", "", ""].map((item, i) => (
                            <GetSchedulesShimmer key={i} />
                          ))}
                        </>
                      ) : (
                        <>
                          {allSchedules.map((item: any, i) => (
                            <div
                              key={i}
                              className="p-[15px] rounded-[10px] shadow-lg bg-white cursor-pointer border-l-[2px] border-l-oya-ghana-red mt-3 lg:mt-0"
                              onClick={() => handleOnScheduleClick(item)}
                            >
                              <div className="flex justify-between items-center">
                                <p><span className="font-medium">Created On: </span>
                                  {moment(item?.created_at).format(
                                    "Do MMM YYYY - hh:mm a"
                                  )}
                                </p>
                                <p className="flex justify-end font-medium">
                                  Fare:{" "}
                                  {`${item?.country?.currency_code} ${item?.amount}`}
                                </p>
                              </div>
                              <hr />
                              <div className="flex gap-3 items-center mt-3">
                                <StopIcon className="h-4 w-4 text-oya-ghana-red" />
                                <p className="truncate w-[230px]">
                                  {item?.source?.place_name}
                                </p>
                              </div>
                              <div className="h-[20px] border-l-[1px] border-dashed border-l-oya-ghana-red ml-[7px]"></div>
                              <div className="flex gap-3 items-center mb-3">
                                <MapPinIcon className="h-4 w-4 text-oya-ghana-red" />
                                <p className="truncate w-[230px]">
                                  {item?.destination?.place_name}
                                </p>
                              </div>

                              {item?.facilitator ?
                                <div>
                                  <p>Created By: <span>{item?.facilitator?.name}</span></p>
                                </div>
                                :
                                <></>
                              }

                              <hr />
                              <div className="flex justify-between items-center mt-2">
                                <div className="flex gap-2 items-center">
                                  <CalendarDaysIcon className="w-5 h-5" />
                                  <div>
                                    <p>Start Trip</p>
                                    <p className="text-gray-500 text-sm">
                                      {moment(item?.departure).format(
                                        "Do MMM YYYY - hh:mm a"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex gap-2 items-center">
                                  <CalendarDaysIcon className="w-5 h-5" />
                                  <div>
                                    <p>Estimated Arrival</p>
                                    <p className="text-gray-500 text-sm">
                                      {moment(item?.estimated_arrival).format(
                                        "Do MMM YYYY - hh:mm a"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {/* Pagination component */}
                          <div className="flex justify-end items-center gap-3 mb-20">
                            <button
                              onClick={() => handlePrevious()}
                              className="bg-oya-ghana-red hover:bg-red-800 text-white font-bold py-2 px-4 rounded disabled:cursor-not-allowed"
                              disabled={page === 1}
                            >
                              Previous
                            </button>
                            <div>Page: {page}</div>
                            <button
                              onClick={() => handleNext()}
                              className="bg-oya-ghana-red hover:bg-red-800 text-white font-bold py-2 px-4 rounded disabled:cursor-not-allowed"
                              disabled={payloadSkip === total}
                            >
                              Next
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                  :
                  <>
                    <div className="mt-4 mb-20">
                      <MaterialReactTable
                        enableColumnResizing
                        columns={columns}
                        data={schedulesDesktop}
                        enableRowSelection={false}
                        rowCount={total}
                        enableColumnActions={false}
                        enableDensityToggle={false}
                        enableFilters={false}
                        enableFullScreenToggle={false}
                        enableSorting={false}
                        manualPagination
                        onPaginationChange={setPagination}
                        state={{
                          pagination,
                          showProgressBars: isFetching,
                          density: "compact",
                          isLoading: isFetching,
                        }}
                        defaultColumn={{
                          maxSize: 400,
                          minSize: 80,
                          size: 120,
                        }}
                      />
                    </div>
                  </>
              }

            </div>
          </div>
        </div>
      </div>

      {openCreateModal ? (
        <CreateScheduleModal
          openModal={openCreateModal}
          handleOpenModal={handleOpenCreateModal}
          handleCloseModal={handleCloseCreateModal}
          handleReFetch={handlereFetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AllScehdules;
