import Swal from "sweetalert2";

const CustomToasts = (function () {
  const success_toast = (text: any) => {
    return Swal.fire({
      title: "Success",
      icon: "success",
      text: text,
      position: "top-right",
      toast: true,
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const error_toast = (text: any) => {
    return Swal.fire({
      title: "Error",
      icon: "error",
      text: text,
      position: "top-right",
      toast: true,
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const warning_toast = (text: any) => {
    return Swal.fire({
      title: "Warning",
      icon: "warning",
      text: text,
      position: "top-right",
      toast: true,
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const info_toast = (text: any) => {
    return Swal.fire({
      title: "Info",
      icon: "info",
      text: text,
      position: "top-right",
      toast: true,
      showConfirmButton: false,
      timer: 3000,
    });
  };

  return {
    success_toast,
    error_toast,
    warning_toast,
    info_toast,
  };
})();

export default CustomToasts;
