import { useEffect, useState } from 'react'
import Select from 'react-select';
import { base_hshs_requests } from '../../helpers/api';
import { RouteConstants } from '../../helpers/RouteConstants';
import { RegionPayload } from '../../pages/hsh/regions/model.regions';

interface theProps {
    onChange: (data: any) => void
    defaultValue?: any
}

const RegionSelector = ({ onChange, defaultValue }: theProps) => {

    const [isFetching, setIsFetching] = useState(false)
    const [regionsList, setAllRegionsList] = useState([]);


    //onload
    useEffect(() => {
        const fetchAllRegions = async () => {
            setIsFetching(true);
            try {
                const response = await base_hshs_requests.get(`${RouteConstants.REGIONS_ROUTE}?sorting=updated_at:desc`);
                // console.log(response?.data?.payload?.items);
                const rawData = response?.data?.payload?.items
                const mapped = rawData.map((item: RegionPayload) => (
                    { label: item?.name, value: item?.id }
                ))
                setAllRegionsList(mapped);
                setIsFetching(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchAllRegions()
    }, [])

    return (
        <>
            <Select
                className="w-full"
                isDisabled={isFetching}
                isClearable={true}
                isSearchable={true}
                options={regionsList}
                isLoading={isFetching}
                placeholder="E.g: Greater Accra"
                onChange={onChange}
                defaultValue={defaultValue}
            />
        </>
    )
}

export default RegionSelector
