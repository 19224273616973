import { Button } from "@material-tailwind/react"
import noAccessImg from "../images/noAccessImg.svg"
import { logout } from "../helpers/utils"

const UnautorizedAccess = () => {
    return (
        <div className="h-screen overflow-hidden">
            <div className="flex flex-col justify-center items-center h-full">
                <img className="w-[400px]" src={noAccessImg} alt="access" />
                <p className="text-xl font-semibold mt-3 text-oya-ghana-red">Unauthorized Access!</p>

                <div className="mt-5">
                    <Button color="red" onClick={logout}>
                        Logout
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default UnautorizedAccess
